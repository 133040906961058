import React from 'react';
import {returnFilterObject} from "../resources/filterKeys";
import {FieldCheckbox} from "../../src/components";
import { excludedCategories, categoryHierarchy, excludedTags } from '../resources/forms';

export const renderCheckboxes = (type = "outcomes") => {
/*
https://www.pluralsight.com/guides/iterate-through-a-json-response-in-jsx-render-for-reactjs
*/
    const checkboxList = []

    let returnFilter = null;

    returnFilter = returnFilterObject(type);

    Object.keys(returnFilter).forEach(function(key) {
        checkboxList.push(
            <FieldCheckbox
                name={key}
                id={key}
                label={returnFilter[key]}
                key={key}
            />
        );
    });

    return checkboxList;
};

export const filterKeys = (filterSelection = "outcomes") => {
    const filterKeyList = returnFilterObject(filterSelection);

    if (filterKeyList) {
        return Object.keys(filterKeyList);
    } 

    return [];
}

export const processCategories = (inputCategories = []) => {
    if (inputCategories.length <= 0) {
        return [];
    }

    // console.log(excludedCategories);

    var newSelectedCategories = [];

    var categorySetup = {};

    for (var i=0; i < inputCategories.length; i++) {

        const categoryInputEntry = inputCategories[i];
        /*
        exclude parent categories
        */
        if (!excludedCategories.includes(categoryInputEntry)) {
            // newSelectedCategories.push(categoryInputEntry);

            /* check parent category, push to array */

            const parentCategory = categoryHierarchy[categoryInputEntry];

            if (parentCategory) {
                // newSelectedCategories.push(parentCategory);

                // if (parentCategory in categorySetup) {

                // }

                // categorySetup[parentCategory] = {...categorySetup[parentCategory], }

                var parentCategoryArray = [];

                if (parentCategory in categorySetup) {
                    parentCategoryArray = categorySetup[parentCategory];
                }

                categorySetup[parentCategory] = [...parentCategoryArray, categoryInputEntry];

            } else {
                categorySetup[categoryInputEntry] = [];
            }
        }
    }

    const categoryKeys = Object.keys(categorySetup).sort();

    for (var i=0; i<categoryKeys.length; i++) {
        const categoryKey = categoryKeys[i];

        const categoryChildren = categorySetup[categoryKey].sort();

        newSelectedCategories.push(categoryKey);

        for (var k=0; k<categoryChildren.length ; k++) {
            newSelectedCategories.push(categoryChildren[k]);
        }

    }

    return newSelectedCategories;
    
}

export const processTags = (inputTags = []) => {
    if (inputTags.length <= 0) {
        return [];
    }

    // console.log(inputTags);

    var newSelectedTags = [];

    for (var i=0; i < inputTags.length; i++) {

        const tagInputEntry = inputTags[i];
        /*
        exclude parent categories
        */
        if (!excludedTags.includes(tagInputEntry)) {
            newSelectedTags.push(tagInputEntry);

        }
    }

    return [...new Set(newSelectedTags)].sort();
}