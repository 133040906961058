import React, { Component } from 'react';
import { string, bool, arrayOf, array, func, number, object } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import moment, { max } from 'moment';
import { required, bookingDateRequired, composeValidators, valueShouldBeNumber, valueShouldBeWithinRange } from '../../util/validators';
import { START_DATE, END_DATE } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, IconSpinner, PrimaryButton, FieldDateInput, FieldTextInput } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';

import css from './BookingDatesForm.css';

const identity = v => v;

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { focusedInput: null };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(values) {
    const { bookingDate, groupSize } = values;
    const { intl } = this.props;
    const requiredMessage = intl.formatMessage({
      id: 'BookingDatesForm.requiredDate',
    });
    const groupSizeInvalid = intl.formatMessage({
      id: 'BookingDatesForm.groupSizeInvalid',
    });
    const startDateErrorMessage = intl.formatMessage({
      id: 'FieldDateRangeInput.invalidBookingDate',
    });

    if (!bookingDate) {
      this.setState({ focusedInput: START_DATE });
    }
    
    const errors = {
      bookingDate: required(requiredMessage)(bookingDate)
        || bookingDateRequired(startDateErrorMessage)(bookingDate),
      groupSize: valueShouldBeNumber(groupSizeInvalid)(groupSize),
    }
    console.log('errors', errors);
    const withErrors = Object.keys(errors).filter(key => errors[key] !== undefined);
    if (withErrors.length > 0) {
      return errors;
    }
    this.props.onSubmit(values);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { date } =
      formValues.values && formValues.values.bookingDate ? formValues.values.bookingDate : {};
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    if (date && !this.props.fetchLineItemsInProgress) {
      // this.props.onFetchTransactionLineItems({
      //   bookingData: { startDate, endDate },
      //   listingId,
      //   isOwnListing,
      // });
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, customPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    if (!unitPrice && !customPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice && unitPrice.currency !== config.currency && !customPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice || customPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitType,
            values,
            timeSlots,
            fetchTimeSlotsError,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            requestQuoteInProgress,
            requestQuoteError,
            minGroupSize,
            maxGroupSize,
          } = fieldRenderProps;
          const { date } = values && values.bookingDate ? values.bookingDate : {};
          
          const bookingStartLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingDateTitle',
          });
          
          let groupSizeCondition = '';
          let groupSizePlaceholder = 10;
          if (minGroupSize > 0) {
            groupSizeCondition += 'mininum ' + minGroupSize;
            groupSizeCondition += maxGroupSize > 0 ? ', ' : '';
            groupSizePlaceholder = minGroupSize;
          }
          if (maxGroupSize > 0) {
            groupSizeCondition += 'maximum ' + maxGroupSize;
          }
          const groupSizeLabel = intl.formatMessage({
            id: 'BookingDatesForm.groupSize',
          }, { groupSizeCondition: groupSizeCondition ? ` (${groupSizeCondition})` : '' });
          const requiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredDate',
          });
          const groupSizeInvalid = intl.formatMessage({
            id: 'BookingDatesForm.groupSizeInvalid',
          });
          const groupSizeOutofRange = intl.formatMessage({
            id: 'BookingDatesForm.groupSizeOutofRange',
          });
    
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidBookingDate',
          });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;
          const pricePerHourRequiredMessage = intl.formatMessage({
            id: 'EditListingDetailsForm.pricePerHourRequired',
          });
    

          // This is the place to collect breakdown estimation data.
          // Note: lineItems are calculated and fetched from FTW backend
          // so we need to pass only booking data that is needed otherwise
          // If you have added new fields to the form that will affect to pricing,
          // you need to add the values to handleOnChange function
          const bookingData =
            date
              ? {
                  unitType,
                  date,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress &&
              !fetchLineItemsError && !requestQuoteError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = (fetchLineItemsInProgress || requestQuoteInProgress) ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = (fetchLineItemsError || requestQuoteError) ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.requestQuoteError" />
            </span>
          ) : null;

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const now = moment();
          const tomorrow = now
            .startOf('day')
            .add(2, 'days')
            .toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);
          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );
          return (
            <Form onSubmit={handleSubmit} className={classes}>
              {timeSlotsError}
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />
              
              <FieldDateInput 
                className={css.bookingDates}
                name="bookingDate"
                id={`${formId}.bookingStartDate`}
                label={bookingStartLabel}
                placeholderText={startDatePlaceholderText}
                format={identity}
                useMobileMargins
                // validate={composeValidators(
                //   required(requiredMessage),
                //   bookingDateRequired(startDateErrorMessage)
                // )}
                disabled={requestQuoteInProgress || isOwnListing}
                timeSlots={timeSlots}
              />
              
              <FieldTextInput
                id={`${formId}.groupSize`}
                name="groupSize"
                className={css.groupSize}
                type="text"
                label={groupSizeLabel}
                placeholder={groupSizePlaceholder}
                // validate={
                //   composeValidators(valueShouldBeNumber(groupSizeInvalid),
                //   valueShouldBeWithinRange(groupSizeOutofRange, minGroupSize, maxGroupSize))
                // }
                // validate={valueShouldBeNumber(groupSizeInvalid)}
                useMobileMargins
                disabled={requestQuoteInProgress || isOwnListing}
              />

              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit" disabled={requestQuoteInProgress || isOwnListing}>
                  <FormattedMessage id="BookingDatesForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  customPrice: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  requestQuoteError: null,
  initialValues: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  customPrice: string,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),
  minGroupSize: number.isRequired,
  maxGroupSize: number.isRequired,
  initialValues: object,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  requestQuoteInProgress: bool.isRequired,
  requestQuoteError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
