import React from 'react';
import css from './ListingPage.css';


const SectionSocialLinkMaybe = props => {
  const { icon, text, link } = props;
  return link ? (
    <div className={css.sectionSocial}>
      <div className={css.detailContent}>
        <a className={css.detailText} href={link} target="_blank">{icon}{text || ''}</a>
      </div>
    </div>
  ) : null;
};

export default SectionSocialLinkMaybe;
