import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { filterKeys, processTags } from '../../util/htmlBuilder';
import { formNames } from '../../resources/forms';
import { EditListingDetailsForm } from '../../forms';
import config from '../../config';

import css from './EditListingDetailsPanel.css';

const formName = formNames["location"];

const EditListingDetailsPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDetailsPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDetailsPanel.createListingTitle" />
  );

  const locationType = publicData && publicData.locationType;
  const duration = publicData && publicData.duration;
  const price_per_hour = publicData && publicData.price_per_hour;
  const minimumParticipants = publicData && publicData.minimumParticipants;
  const maximumParticipants = publicData && publicData.maximumParticipants;
  const client = publicData && publicData.client


  const initialValuesObject = {
    duration,
    price_per_hour,
    minimumParticipants,
    maximumParticipants,
    locationType,
    client
  }


  const categoryOptions = findOptionsForSelectFilter('categories', config.custom.filters);
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDetailsForm
        className={css.form}
        initialValues={initialValuesObject}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { duration, price_per_hour, minimumParticipants, maximumParticipants, locationType = [], client } = values;


          console.log(locationType);

          var locationTypeSave = [];

          var checkLocation = false;

          checkLocation = locationType.find(
            function(str) {
                return str == "virtual";
              }
          );

          if (checkLocation) {
            locationTypeSave.push("virtual");
          }

          var checkOnsite = false;

          checkOnsite = locationType.find(
            function(str) {
                return str == "onsite";
              }
          );

          if (checkOnsite) {
            locationTypeSave.push("onsite");
          }


          var checkOffsite = false;

          checkOffsite = locationType.find(
            function(str) {
                return str == "offsite";
              }
          );

          if (checkOffsite) {
            locationTypeSave.push("offsite");
          }



          const updateValues = {
            publicData: { 
              duration: duration,
              price_per_hour: price_per_hour,
              minimumParticipants: minimumParticipants,
              maximumParticipants: maximumParticipants,
              locationType: locationTypeSave,
              client: client
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={categoryOptions}
      />
    </div>
  );
};

EditListingDetailsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDetailsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDetailsPanel;
