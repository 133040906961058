import { returnSendGrid } from '../util/api';
import { storableError } from '../util/errors';
import * as log from '../util/log';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/EmailSender/SET_INITIAL_VALUES';
export const SEND_EMAIL_REQUEST = 'app/EmailSender/SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'app/EmailSender/SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERROR = 'app/EmailSender/SEND_EMAIL_ERROR';

// ================ Reducer ================ //

const initialState = {
  sendEmailInProgress: false,
  sendEmailError: null,
  sendEmailSuccess: false,
};

export default function emailSenderReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState };
    case SEND_EMAIL_REQUEST:
      return {
        ...state,
        sendEmailInProgress: true,
        sendEmailError: null,
        sendEmailSuccess: false,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailInProgress: false,
        sendEmailSuccess: true,
      };
    case SEND_EMAIL_ERROR:
      return {
        ...state,
        sendEmailInProgress: false,
        sendEmailError: payload,
        sendEmailSuccess: false,
      };

    default:
      return state;
  }
}

export const setInitialValues = () => ({
  type: SET_INITIAL_VALUES,
});

const sendEmailRequest = () => ({ type: SEND_EMAIL_REQUEST });

const sendEmailSuccess = () => ({
  type: SEND_EMAIL_SUCCESS,
});

const sendEmailError = e => ({
  type: SEND_EMAIL_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const sendEmail = params => (dispatch, getState, sdk) => {
  const { name, email, message, phone, subject, htmlFormatted, emailSubjectCustom } = params;

  dispatch(sendEmailRequest());

  let html = `<strong>Name: </strong>${name}<br><strong>Email: </strong>${email}<br><strong>Phone: </strong>${phone}<br><strong>Subject: </strong>${subject}<br><strong>Message: </strong><div style=\"white-space: pre-line;\"> ${message} </div>`;

  if (htmlFormatted) {
    html = htmlFormatted;
  }

  let emailSubject = "New Contact Us form submitted";

  if (emailSubjectCustom) {
    emailSubject = emailSubjectCustom;
  }
  returnSendGrid({
    email,
    subject: emailSubject,
    html,
    addReplyTo: true,
  }).then(response => {
    console.log('response ::', response);
    if(response !== 'email sent') {
      throw response;
    } else {
      dispatch(sendEmailSuccess());
    }
  })
  .catch(e => {
    dispatch(sendEmailError(storableError(e)));
    log.error(e, 'send-email-failed');
  })
};

