import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import config from '../../config';
import * as custom from '../../marketplace-custom-config';
import { findOptionsForSelectFilter, parseSelectFilterOptions } from '../../util/search';
import { categoryOptions } from '../../resources/forms';
import map from 'lodash/map';
import { pay } from '../TransactionPage/TransactionPage.duck';

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_CATEGORY = 'app/SearchPage/SEARCH_LISTINGS_CATEGORY';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  categoryPagination: null,
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        currentPageResultIds: [],
        searchMapListingIds: [],
        searchListingsError: null,
        categoryPagination: {},
        pagination: null,
      };
    case SEARCH_LISTINGS_CATEGORY:
      const results = resultIds(payload.data);
      const { currentPageResultIds } = state;
      

      // console.log('cat', payload.cat);
      if(results.length > 0) {
        currentPageResultIds.push({cat: payload.cat, data: results });
      }
      return {
        ...state,
        // currentPageResultIds: resultIds(payload.data),
        currentPageResultIds, 
        categoryPagination: results ? { ...state.categoryPagination, [payload.cat]: payload.data.meta } : state.categoryPagination,
      };
    case SEARCH_LISTINGS_SUCCESS:
      // console.log('currentPageResultIds', state.categoryPagination);
      const cats = Object.keys(state.categoryPagination);
      let totalItems = 0;
      let pagination = {
        page: 1,
        perPage: 4,
        totalItems: 0,
        totalPages: 0,
      }

      if (cats.length === 1) {
        pagination = state.categoryPagination[cats[0]];
      } else {
        totalItems = cats.reduce((total, cat) => 
          total + state.categoryPagination[cat].totalItems, 0);
        pagination = {
          ...pagination,
          totalItems,
          totalPages: 1,
        }
      }
      return {
        ...state,
        searchInProgress: false,
        pagination
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingCategory = ({ cat, response }) => ({
  type: SEARCH_LISTINGS_CATEGORY,
  payload: { cat, data: response.data },
});

export const searchListingsSuccess = () => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: null,
});
export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = searchParams => async (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const datesSearchParams = datesParam => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[0] : null;
    const isNightlyBooking = config.bookingUnitType === 'line-item/night';
    const endDate =
      hasValues && isNightlyBooking ? values[1] : hasValues ? getExclusiveEndDate(values[1]) : null;

    return hasValues
      ? {
          start: formatDateStringToUTC(startDate),
          end: formatDateStringToUTC(endDate),
          // Availability can be full or partial. Default value is full.
          availability: 'full',
        }
      : {};
  };

  const { perPage, price, dates, pub_categories, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    pub_categories,
    per_page: perPage,
  };

  const fetchListings = async (cats, perPage) => {
    
    cats.forEach((cat, index) => {
      const queryParams = {
        ...params,
        pub_categories: cat.key,
        per_page: perPage,
      };

      sdk.listings
      .query(queryParams)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(searchListingCategory({cat: cat.key, response }));
        
        if (index === cats.length - 1) { 
          dispatch(searchListingsSuccess());
        }
        return response;
      })
      .catch(e => {
        throw e;
      });
    });
  }
  const subCats = findOptionsForSelectFilter('categories', custom.filters).filter(cat => !!cat.parent && cat);
  const mainCats = findOptionsForSelectFilter('mainCategories', custom.filters).map(cat => cat.key);

  let perPageParams = 4;

  if (pub_categories) {
    let selectedCats = parseSelectFilterOptions(pub_categories);

    if (selectedCats.length === 1 && !mainCats.includes(selectedCats[0])) {
      // console.log('show default listings', selectedCats);
      selectedCats = subCats.filter(cat => cat.key === selectedCats[0]);
      perPageParams = perPage;
    } else if (selectedCats.length === 1 && mainCats.includes(selectedCats[0])) {
      // console.log('show main cat', selectedCats);
      selectedCats = subCats.filter(cat => cat.parent === selectedCats[0]);
    } else {
      selectedCats = subCats.filter(cat => selectedCats.includes(cat.key));
    }
    
    // console.log('show all cat', selectedCats);
    fetchListings(selectedCats, perPageParams).then(() => {
    }).catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
  } else {
      fetchListings(subCats, perPageParams).then(() => {
      }).catch(e => {
        dispatch(searchListingsError(storableError(e)));
        throw e;
      });
  }
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};
