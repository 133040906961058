import React, { Component } from 'react';
import { string, array, object, bool, func } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ListingCard, IconArrowHead, IconNext, NamedLink } from '../../components';

import css from './SectionCategoryListings.css';


const panelMediumWidth = 50;
const panelLargeWidth = 62.5;
const cardRenderSizes = [
  '(max-width: 767px) 100vw',
  `(max-width: 1023px) ${panelMediumWidth}vw`,
  `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
  `${panelLargeWidth / 3}vw`,
].join(', ');

class SectionCategoryListings extends Component {
  constructor(props) {
    super(props);
    this.state = { enablePrevious: false, enableNext: true };
    this.scrollSectionNext = this.scrollSectionNext.bind(this);
    this.scrollSectionPrevious = this.scrollSectionPrevious.bind(this);
  }
  scrollSectionPrevious(e) {
    e.preventDefault();
    // eslint-disable-next-line no-console
    // this.setState({ transform: this.state.transform + 100  });
    const scrollLeft = this.section.scrollLeft - this.section.offsetWidth;
    this.section.scrollLeft = scrollLeft;
    console.log('scrollPrev', scrollLeft);
    if(scrollLeft <= 0) {
      this.setState({ enableNext: true, enablePrevious: false });
    } else {
      this.setState({ enableNext: true, enablePrevious: true });
    }
  }
  scrollSectionNext(e) {
    e.preventDefault();
    // eslint-disable-next-line no-console
    // this.setState({ transform: this.state.transform - 100  });
    
    const scrollLeft = this.section.scrollLeft + this.section.offsetWidth;
    this.section.scrollLeft = scrollLeft;
    console.log('scrollNext', scrollLeft);
    console.log('scrollWidth', this.section.scrollWidth);

    if(scrollLeft >= (this.section.scrollWidth - this.section.offsetWidth) ) {
      this.setState({ enableNext: false, enablePrevious: true });
    } else {
      this.setState({ enableNext: true, enablePrevious: true });
    }
  }
  
  render() {
    const { rootClassName, className, listings, category, categoryOptions, fetchInProgress, onShowAll } = this.props;
    const { enablePrevious, enableNext } = this.state;
    if (! category) {
      return null;
    }
    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <h2 className={css.sectionTitle}>
          {category.label}
        </h2>
        {fetchInProgress ? <FormattedMessage id="SectionCategoryListings.loadingData"/> : (
          <div className={css.scrollingSection}>
            <span disabled={!enablePrevious} onClick={this.scrollSectionPrevious}><IconArrowHead direction="left" rootClassName={enablePrevious ? css.arrowIcon : css.arrowIconDisabled} /></span>
            <div id={category.key} className={css.listingCards} ref={section => {this.section = section;}}>
              {listings.map(l => (
                <ListingCard
                  className={css.listingCard}
                  key={l.id.uuid}
                  listing={l}
                  renderSizes={cardRenderSizes}
                  categoryOptions={categoryOptions}
                />
              ))}
            </div>
            <span disabled={!enableNext} onClick={this.scrollSectionNext}><IconArrowHead direction="right" rootClassName={enableNext ? css.arrowIcon : css.arrowIconDisabled} /></span>
          </div>
        )}
        {!fetchInProgress && (
          <NamedLink
            name="SearchPage"
            to={{
              search:
                `pub_categories=${category.key}`,
            }}
            className={css.showAll}
          >
            <FormattedMessage id="SectionCategoryListings.showAll" />
            <IconNext rootClassName={css.nextIcon} />
          </NamedLink>
        )}
      </div>
    );
  }
};

SectionCategoryListings.defaultProps = { rootClassName: null, className: null, listings: [], category: null };

SectionCategoryListings.propTypes = {
  rootClassName: string,
  className: string,
  listings: array,
  category: object,
  categoryOptions: array.isRequired,
  fetchInProgress: bool.isRequired,
};

export default SectionCategoryListings;
