import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '..';
import groupImage from './assets/about-group.png';
import womanImage from './assets/about-girl.png';
import manImage from './assets/about-guy.png';

import css from './SectionAboutHero.css';

const SectionAboutHero = props => {
  const { rootClassName, className, isMobileLayout } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.textContent}>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionAboutHero.title" />
          </h1>
          <p>
          Revive is a marketplace that brings people together. We aim to make the workplace healthier and happier through shared experiences.
          </p>
          <p>
          We leverage our community of validated vendors and develop workplace initiatives that enhance community, performance, and engagement! 
          </p>
          <p>
          Our programs are developed by experts in the human condition. We take a data-driven approach in providing insights that boost the employee experience to better retain and develop world-class talent!
          </p>
          <NamedLink
            name="SearchPage"
            className={css.heroButton}
          >
            <FormattedMessage id="SectionAboutHero.browse" />
          </NamedLink>
        </div>
        <div className={css.imageContainer}>
          <div className={css.groupImage}>
            <img src={groupImage} className={css.imageBackground} />
          </div>
          <div className={css.imageRow}>
            <div className={css.womanImage}>
              <img src={womanImage} className={css.imageBackground} />
            </div>
            <div className={css.manImage}>
              <img src={manImage} className={css.imageBackground} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionAboutHero.defaultProps = { rootClassName: null, className: null };

SectionAboutHero.propTypes = {
  rootClassName: string,
  className: string,
  isMobileLayout: bool.isRequired,
};

export default SectionAboutHero;
