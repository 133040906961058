import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import { propTypes } from '../../util/types';
import css from './UserNav.css';

export const UserNavComponent = props => {
  const { className, rootClassName, selectedPageName, currentUser } = props;
  const classes = classNames(rootClassName || css.root, className);

  // console.log(currentUser);

  var tabs = [];

  var is_admin = false;

  if (currentUser && currentUser.attributes.profile.publicData.is_admin) {
    is_admin = currentUser.attributes.profile.publicData.is_admin;
  }

  if (is_admin) {
    tabs = [
      {
        text: <FormattedMessage id="ManageListingsPage.yourListings" />,
        selected: selectedPageName === 'ManageListingsPage',
        linkProps: {
          name: 'ManageListingsPage',
        },
      },
      {
        text: <FormattedMessage id="ManageListingsPage.profileSettings" />,
        selected: selectedPageName === 'ProfileSettingsPage',
        disabled: false,
        linkProps: {
          name: 'ProfileSettingsPage',
        },
      },
      {
        text: <FormattedMessage id="ManageListingsPage.accountSettings" />,
        selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
        disabled: false,
        linkProps: {
          name: 'ContactDetailsPage',
        },
      },
    ];

  } else {
    tabs = [
      {
        text: <FormattedMessage id="ManageListingsPage.profileSettings" />,
        selected: selectedPageName === 'ProfileSettingsPage',
        disabled: false,
        linkProps: {
          name: 'ProfileSettingsPage',
        },
      },
      {
        text: <FormattedMessage id="ManageListingsPage.accountSettings" />,
        selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
        disabled: false,
        linkProps: {
          name: 'ContactDetailsPage',
        },
      },
    ];
  }

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNavComponent.propTypes = {
  currentUser: propTypes.currentUser,
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const UserNav = compose(
  connect(mapStateToProps),
)(UserNavComponent);

export default UserNav;
