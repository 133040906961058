import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import moment from 'moment';
import { LINE_ITEM_NIGHT, DATE_TYPE_DATE, propTypes } from '../../util/types';
import { dateFromAPIToLocalNoon } from '../../util/dates';
import { string } from 'prop-types';

import css from './BookingBreakdown.css';
const Decimal = require('decimal.js');

const BookingPeriod = props => {
  const { startDate, endDate, dateType, groupSize } = props;

  const timeFormatOptions =
    dateType === DATE_TYPE_DATE
      ? {
          weekday: 'long',
        }
      : {
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
        };

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
  };

  return (
    <div className={css.bookingPeriodSection}>
      <div className={css.bookingPeriod}>
        <div className={css.bookingPeriodSection}>
          <div className={css.dayLabel}>
            <FormattedMessage id="BookingBreakdown.bookingDate" />
          </div>
        </div>

        <div className={css.bookingPeriodSectionRight}>
          <div className={css.dayInfo}>
            <FormattedDate value={startDate} {...timeFormatOptions} />{`, `}
          </div>
          <div className={css.itemLabel}>
            <FormattedDate value={startDate} {...dateFormatOptions} />
          </div>
        </div>
      </div>
      {groupSize > 0 && (
        <div className={css.bookingPeriod}>
          <div className={css.bookingPeriodSection}>
            <div className={css.dayLabel}>
              <FormattedMessage id="BookingBreakdown.groupSizeLabel" />
            </div>
          </div>
          <div className={css.bookingPeriodSectionRight}>
            <div className={css.itemLabel}>
              <FormattedMessage id="BookingBreakdown.groupSize" values={{ groupSize }} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const LineItemBookingPeriod = props => {
  const { booking, unitType, dateType, groupSize } = props;

  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = dateFromAPIToLocalNoon(displayStart || start);
  const localEndDateRaw = dateFromAPIToLocalNoon(displayEnd || end);

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const endDay = isNightly ? localEndDateRaw : moment(localEndDateRaw).subtract(1, 'days');

  return (
    <>
      <div className={css.lineItem}>
        <BookingPeriod startDate={localStartDate} endDate={endDay} dateType={dateType} groupSize={groupSize} />
      </div>
      {/* <hr className={css.totalDivider} /> */}
    </>
  );
};
LineItemBookingPeriod.defaultProps = { dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
  groupSize: propTypes.number.isRequired,
};

export default LineItemBookingPeriod;
