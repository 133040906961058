import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import Masonry from 'react-masonry-css'
import classNames from 'classnames';

import css from './SectionFeaturedExperiences.css';
import guy from './assets/guy.png';
import food from './assets/food.png';
import escape from './assets/escape.png';
import wine from './assets/wine.png';
import man from './assets/man.png';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
const MOBILE_BREAKPOINT = 767;

const SectionFeaturedExperiences = props => {
    const { isMobileLayout, history } = props;
    const MasonCard = props => {
        const extraStyle = css.cardEscapeDiv;
        const extraCaptionStyle = css.cardCaptionDiv;
        const { pathParams } = props;
        const link = createResourceLocatorString('ListingPage', routeConfiguration(), pathParams, {});
        var finalClass = css.imageContainer + " " + extraStyle;
        var finalCaptionClass = css.captionContainer + " " + extraCaptionStyle;
        return (
            <div className={classNames(css.actualCard, props.className)} onClick={ () => history.push(link) } >
                <div className={finalClass} >
                    <img src={props.src} className={css.image}/>
                </div>
                <div className={finalCaptionClass}>
                    <FormattedMessage id={props.caption}/>
                </div>
            </div>
    )};

    const breakpointColumnsObj = {
        default: 2,
        1100: 2,
        700: 1,
        500: 1
      };

    const cardLinks = {
        guy: { id: '5fdf652d-da4c-443d-b22c-dc2757c5b395', slug: 'david-romanelli-s-happy-is-the-new-healthy' },
        food: { id: '5fdf652d-6d43-4c07-8ea2-b046571d7c04', slug: 'world-famous-tacos-and-guac-with-food-network-winner-daniel-shemtob' }, 
        escape: { id: '5fdf652d-5f03-4f35-b248-3ebe759877e7', slug: 'virtual-escape-room' },
        man: { id: '5ff7ebd8-b4a2-4063-8410-4b06491a078d', slug: 'dave-asprey-s-sleep-challenge' },
        wine: { id: '5fdf652d-482b-439f-8f9b-5fb2eb24936a', slug: 'virtual-wine-tasting-and-kit-with-in-good-taste' },
    }
    
    return (
        <div className={css.root}>
            <div className={css.titleContainer}>
                <FormattedMessage id="SectionFeaturedExperiences.title" />
            </div>
            <div className={css.subtitleContainer}>
                <FormattedMessage id="SectionFeaturedExperiences.subtitle" />
            </div>
            {isMobileLayout ? (
                <div className={css.mobileContent}>
                    <div className={css.masonContainer}>
                        <MasonCard pathParams={cardLinks.guy} src={guy} className={css.cardGuy} caption="SectionFeaturedExperiences.featuredOne" />
                        <MasonCard pathParams={cardLinks.food} src={food} className={css.cardFood} caption="SectionFeaturedExperiences.featuredTwo" />
                        <MasonCard pathParams={cardLinks.man} src={man} className={css.cardMan} caption="SectionFeaturedExperiences.featuredThree" />
                        <MasonCard pathParams={cardLinks.escape} src={escape} className={css.cardEscape} caption="SectionFeaturedExperiences.featuredFour" cardEscape={true}/>
                        <MasonCard pathParams={cardLinks.wine} src={wine} className={css.cardWine} caption="SectionFeaturedExperiences.featuredFive" />
                    </div>
                </div>
            ) : 
                <div className={css.masonContainer}>
                    <MasonCard pathParams={cardLinks.guy} src={guy} className={css.cardGuy} caption="SectionFeaturedExperiences.featuredOne" />
                    <div className={css.masonry}>
                        <div className={css.row}
                            >
                            <MasonCard pathParams={cardLinks.food} src={food} className={css.cardFood} caption="SectionFeaturedExperiences.featuredTwo" />
                            <MasonCard pathParams={cardLinks.man} src={man} className={css.cardMan} caption="SectionFeaturedExperiences.featuredThree" />
                        </div>
                        <div className={css.row}
                            >
                            <MasonCard pathParams={cardLinks.escape} src={escape} className={css.cardEscape} caption="SectionFeaturedExperiences.featuredFour" cardEscape={true}/>
                            <MasonCard pathParams={cardLinks.wine} src={wine} className={css.cardWine} caption="SectionFeaturedExperiences.featuredFive" />
                        </div>
                    </div>
                </div>
            }
            <div className={css.buttonContainer}>
                <NamedLink name="SearchPage" className={css.browseButton}>
                    <FormattedMessage id="SectionFeaturedExperiences.button"/>
                </NamedLink>
            </div>
        </div>
    );
}

export default SectionFeaturedExperiences;