import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditListingBusinessBioForm } from '../../forms';
import config from '../../config';

import css from './EditListingBusinessBioPanel.css';

const EditListingBusinessBioPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingBusinessBioPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingBusinessBioPanel.createListingTitle" />
  );

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingBusinessBioForm
        className={css.form}
        initialValues={{ 
          businessAbout: publicData.businessAbout,
          instagramLink: publicData.instagram_link,
          facebookLink: publicData.facebook_link,
          linkedinLink: publicData.linkedin_link,
          twitterLink: publicData.twitter_link,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { businessAbout, instagramLink, facebookLink, linkedinLink, twitterLink } = values;
          const updateValues = {
            publicData: { 
              businessAbout: businessAbout,
              instagram_link: instagramLink,
              facebook_link: facebookLink,
              linkedin_link: linkedinLink,
              twitter_link: twitterLink,
            },
          };

          onSubmit(updateValues);
        }}

        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={categoryOptions}
      />
    </div>
  );
};

EditListingBusinessBioPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingBusinessBioPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingBusinessBioPanel;
