import React from 'react';
import { returnFilterObject } from "../../resources/filterKeys";

import css from './ListingPage.css';

const availableTags = returnFilterObject();

// const getText = key => {
//   availab
// }
// Object.keys(returnFilter).forEach(function(key) {
//     checkboxList.push(
//         <FieldCheckbox
//             name={key}
//             id={key}
//             label={returnFilter[key]}
//             key={key}
//         />
//     );
// });

const TagElement = props => (
  <span className={css.tagContainer}>
    { availableTags[props.tag] || props.tag }
  </span>
);

const SectionTagsMaybe = props => {
  const { tags } = props;

  return tags ? (
    <div className={css.tagsSection}>
      {tags.map(tag => <TagElement key={tag} tag={tag} />) }
    </div>
  ) : null;
};

export default SectionTagsMaybe;
