import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SectionExperienceCategories.css';
import LightBulb from './assets/LightBulb2.png';
import Guy from './assets/Guy3.png';
import Girl from './assets/Girl2.png';
import Helicopter from './assets/Helicopter2.png';
import { func } from 'prop-types';

const SectionExperienceCategories = props => {
    const { handleClick } = props;
    const generateCategoryRow = () => {
        //TODO
    }


    const categories = (
        <div className={css.categoryRow}>
            <div className = {css.category} id={css.LearnAndDev} onClick={() => handleClick('learning-development')}>
                <img src={LightBulb} className={css.categoryImageLeft}/>
                <div className={css.categoryTitle}>
                    <FormattedMessage id="SectionExperienceCategories.learningAndDevelopment"/>
                </div>
            </div>
            <div className = {css.category} id={css.HealthAndWell} onClick={() => handleClick('health-wellness')}>
                <img src={Guy} className={css.categoryImageLeft}/>
                <div className={css.categoryTitle}>
                    <FormattedMessage id="SectionExperienceCategories.healthAndWellness"/>
                </div>
            </div>
            <div className = {css.category} id={css.TeamBuild} onClick={() => handleClick('team-building-appreciation')}>
                <div className={css.categoryTitle}>
                    <FormattedMessage id="SectionExperienceCategories.teamBuildingAndAppreciation"/>
                </div>
                <img src={Girl} className={css.categoryImageRight}/>
            </div>
            <div className = {css.category} id={css.OffsiteAndRetreats} onClick={() => handleClick('offsites-retreats')}>
                <div className={css.categoryTitle}>
                    <FormattedMessage id="SectionExperienceCategories.offsitesAndRetreats"/>
                </div>
                <img src={Helicopter} className={css.categoryImageRight}/>
            </div>
        </div>
    );

    return (
        <div className={css.root}>
            <div className={css.titleContainer}>
                <FormattedMessage id="SectionExperienceCategories.title" />
            </div>
            <div className={css.subtitleContainer}>
                <FormattedMessage id="SectionExperienceCategories.subTitle" />
            </div>
            <div>
                {categories}
            </div>
        </div>
    )
};


SectionExperienceCategories.propTypes = {
  handleClick: func.isRequired,
};


export default SectionExperienceCategories;