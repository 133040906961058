import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SectionCompanies.css';
import apple from './assets/apple-logo-bw.png';
import aliant from './assets/aliant-logo-bw.png';
import amazon from './assets/amazon-logo-bw.png';
import hp from './assets/hp-logo-bw.png';
import goodrx from './assets/good-rx-bw.png';
import blisspoint from './assets/blisspoint-bw.png';
import oracle from './assets/oracle-bw.png';
import tatari from './assets/tatari-bw.png';
import northwestern from './assets/northwestern-mutual-bw.png';
import kaiser from './assets/kaiser-logo-bw.png';
import ghirardelli from './assets/ghirardelli-bw.png';
import twitch from './assets/twitch-bw.png';
import structionSite from './assets/structionSite-bw.png';
import gartner from './assets/gartner-bw.png';


const SectionCompanies = props => {

    return (
        <div className={css.companiesContainer}>
            <div className={css.titleContainer}>
                <h2 className={css.title}>
                    <FormattedMessage id="SectionCompanies.title" />
                </h2>
            </div>
            <div className={css.imgContainer}>
                <img src={apple} />
                <img src={aliant} />
                <img src={amazon} />
                <img src={blisspoint} />
                <img src={hp} />
                <img src={gartner} />
                <img src={ghirardelli} />
                <img src={goodrx} />
                <img src={kaiser} />
                <img src={northwestern} />
                <img src={oracle} />
                <img src={structionSite} />
                <img src={tatari} />
                <img src={twitch} />
            </div>
        </div>
    );
};

export default SectionCompanies;