import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  SectionCategoryListings,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/reviveFacebook-1200x630.jpg';
import twitterImage from '../../assets/reviveTwitter-600x314.jpg';
import css from './ExperiencesPage.css';
import { findOptionsForSelectFilter } from '../../util/search';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { fetchListings } from './ExperiencesPage.duck';


const MOBILE_BREAKPOINT = 767;
const RESULT_PAGE_SIZE = 8;

export const ExperiencesPageComponent = props => {
  const {
    intl,
    learningDevListings,
    healthWellnessListings,
    entertainmentSocialListings,
    offsitesRetreatsListings,
    scrollingDisabled,
    fetchInProgress,
    history,
    filterConfig } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'ExperiencesPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'ExperiencesPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const categoryOptions = findOptionsForSelectFilter('categories', filterConfig);
  const learningDev = categoryOptions.find(cat => cat.key === 'learning-development');
  const healthWellness = categoryOptions.find(cat => cat.key === 'health-wellness');
  const entertainmentSocial = categoryOptions.find(cat => cat.key === 'team-building-appreciation');
  const offsitesRetreats = categoryOptions.find(cat => cat.key === 'offsites-retreats');

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.sectionHeading}>
            <div className={css.sectionHeadingTitle}>Booking experiences for your team</div>
            <div className={css.sectionHeadingSubtitle}>Browse Revive’s uniquely curated marketplace and discover your team’s next workplace experience.</div>
            <NamedLink
              name="SearchPage"
              className={css.browseButton}
            >
              <FormattedMessage id="ExperiencesPage.browseAll" />
            </NamedLink>
            
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionCategoryListings
                  listings={learningDevListings}
                  category={learningDev}
                  categoryOptions={categoryOptions}
                  fetchInProgress={fetchInProgress}
                />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionCategoryListings
                  listings={healthWellnessListings}
                  category={healthWellness}
                  categoryOptions={categoryOptions}
                  fetchInProgress={fetchInProgress} 
                />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionCategoryListings
                  listings={entertainmentSocialListings}
                  category={entertainmentSocial}
                  categoryOptions={categoryOptions}
                  fetchInProgress={fetchInProgress} 
                />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionCategoryListings
                  listings={offsitesRetreatsListings}
                  category={offsitesRetreats}
                  categoryOptions={categoryOptions}
                  fetchInProgress={fetchInProgress} 
                />
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

ExperiencesPageComponent.defaultProps = {
  learningDevListings: [],
  fetchListingsError: null,
  params: {},
  filterConfig: config.custom.filters,
};

const { bool, object, shape, string, array, func } = PropTypes;

ExperiencesPageComponent.propTypes = {
  learningDevListings: array,
  healthWellnessListings: array,
  entertainmentSocialListings: array,
  offsitesRetreatsListings: array,
  scrollingDisabled: bool.isRequired,
  fetchInProgress: bool.isRequired,
  fetchListingsError: propTypes.error,
  params: object,
  filterConfig: propTypes.filterConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

  
const mapStateToProps = state => {
  const {
    listingsResultIds,
    fetchInProgress,
    fetchListingsError,
    params,
  } = state.ExperiencesPage;

  
  const learningDevListings = getListingsById(state, !!listingsResultIds && listingsResultIds.learningDev || []);
  const healthWellnessListings = getListingsById(state, !!listingsResultIds && listingsResultIds.healthWellness || []);
  const entertainmentSocialListings = getListingsById(state, !!listingsResultIds && listingsResultIds.entertainmentSocial || []);
  const offsitesRetreatsListings = getListingsById(state, !!listingsResultIds && listingsResultIds.offsitesRetreats || []); 

  return {
    learningDevListings,
    healthWellnessListings,
    entertainmentSocialListings,
    offsitesRetreatsListings,
    scrollingDisabled: isScrollingDisabled(state),
    fetchInProgress,
    fetchListingsError,
    params,
  };
};


const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  // onActivateListing: listingId => dispatch(setActiveListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ExperiencesPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl
)(ExperiencesPageComponent);

ExperiencesPage.loadData = (params, search) => {
  return fetchListings({
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

export default ExperiencesPage;
