import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import config from '../../config';

// ================ Action types ================ //

export const FEATURED_LISTINGS_REQUEST = 'app/SearchPage/FEATURED_LISTINGS_REQUEST';
export const FEATURED_LISTINGS_SUCCESS = 'app/SearchPage/FEATURED_LISTINGS_SUCCESS';
export const FEATURED_LISTINGS_ERROR = 'app/SearchPage/FEATURED_LISTINGS_ERROR';


// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchListingsError: null,
  listingsResultIds: null,
  params: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FEATURED_LISTINGS_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
        fetchListingsError: null,
      };
    case FEATURED_LISTINGS_SUCCESS:
      return {
        ...state,
        listingsResultIds: { ...state.listingsResultIds, [payload.cat]: resultIds(payload.data) },
        fetchInProgress: false,
      };
    case FEATURED_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchInProgress: false, fetchListingsError: payload };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const fetchListingsRequest = params => ({
  type: FEATURED_LISTINGS_REQUEST,
  payload: { params },
});

export const fetchListingsSuccess = ({ cat, response }) => ({
  type: FEATURED_LISTINGS_SUCCESS,
  payload: { cat, data: response.data },
});

export const fetchListingsError = e => ({
  type: FEATURED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchListings = params => async (dispatch, getState, sdk) => {
  dispatch(fetchListingsRequest(params));
  const { perPage, ...rest } = params;
  const queryParams = {
    ...rest,
    pub_categories: 'learning-development',
    per_page: perPage,
  };

  sdk.listings
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchListingsSuccess({cat: 'learningDev', response }));
      return response;
    })
    .catch(e => {
      dispatch(fetchListingsError(storableError(e)));
      throw e;
    });
    
  sdk.listings
    .query({ ...queryParams, pub_categories: 'health-wellness'})
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchListingsSuccess({cat: 'healthWellness', response }));
      return response;
    })
    .catch(e => {
      dispatch(fetchListingsError(storableError(e)));
      throw e;
    });
    
  sdk.listings
    .query({ ...queryParams, pub_categories: 'team-building-appreciation'})
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchListingsSuccess({cat: 'entertainmentSocial', response }));
      return response;
    })
    .catch(e => {
      dispatch(fetchListingsError(storableError(e)));
      throw e;
    });
    
  sdk.listings
    .query({ ...queryParams, pub_categories: 'offsites-retreats'})
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchListingsSuccess({cat: 'offsitesRetreats', response }));
      return response;
    })
    .catch(e => {
      dispatch(fetchListingsError(storableError(e)));
      throw e;
    });
};

