import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SectionScienceBacked.css';
import vector1 from './assets/Vector1.png';
import vector2 from './assets/Vector2.png';
import vector3 from './assets/Vector3.png';
import vector4 from './assets/Vector4.png';


const SectionScienceBacked = props => {

    const ScienceBackedCategory = (category) => (
        <div className={css.imageContainer}>
            <img src={category.vector} className={css.vectorBackground}/>
            <div className={css.captionContainer}>
                <FormattedMessage id={category.caption}/>
            </div>
        </div>
    );

    return(
        <div className={css.root}>
            <div className={css.titleContainer}>
                <FormattedMessage id="SectionScienceBacked.title"/>
            </div>

            <div className={css.subtitleContainer}>
                <FormattedMessage id="SectionScienceBacked.subtitle" />
            </div>
            <div className={css.scienceBackedContainer}>
                <ScienceBackedCategory vector={vector1} caption="SectionScienceBacked.boostEngagement" />
                <div className={css.scienceBackedSpacer}></div>
                <ScienceBackedCategory vector={vector2} caption="SectionScienceBacked.increaseConnection" />
                <div className={css.scienceBackedSpacer}></div>
                <ScienceBackedCategory vector={vector3} caption="SectionScienceBacked.drivePerformance" />
                <div className={css.scienceBackedSpacer}></div>
                <ScienceBackedCategory vector={vector4} caption="SectionScienceBacked.enhanceWellBeing" />
            </div>
        </div>
    );
}

export default SectionScienceBacked;