export const formNames = {
    "categories": "categories",
    "outcomes": "tags",
    "location": "locationType",
}

export const categoryParents = [
    "learning-development",
    "health-wellness",
    "team-building-appreciation",
    "offsites-retreats"
]

export const excludedCategories = [
    ...categoryParents,
    "entertainment-social",
    "engagement",
    "engagament"
];

export const excludedTags = [
    "personal-growth",
    "collaboration",
    "mindfulness",
    "stress-reduction",
    "fun",
    "leadership-development",
    "trust-climate",
    "confidence",
    "personal-development",
    "focus",
    "prioritization",
    "energy-boost",
    "health",
    "nutrition",
    "creativity",
    "team-building",
    "self-awareness",
    "mental-endurance",
    "mutual-accountability",
    "team-development"
];

export const categoryHierarchy = {
    "breathwork": "health-wellness",
    "communication": "learning-development",
    "cooking-classes": "team-building-appreciation",
    "creative": "team-building-appreciation",
    "diversity-equity-and-inclusion": "learning-development",
    "event": "team-building-appreciation",
    "executive-retreats": "offsites-retreats",
    "fitness": "health-wellness",
    "games": "team-building-appreciation",
    "gifts-celebrations": "team-building-appreciation",
    "health": "health-wellness",
    "keynote-speakers": "learning-development",
    "las-vegas-offsites": "offsites-retreats",
    "leadership-development": "learning-development",
    "life-skills": "learning-development",
    "los-angeles-offsites": "offsites-retreats",
    "meditation-mindfulness": "health-wellness",
    "nutrition": "health-wellness",
    "organizational-development": "learning-development",
    "personal-development": "learning-development",
    "personal-wellness": "health-wellness",
    "productivity": "learning-development",
    "social-responsibility": "team-building-appreciation",
    "specialty-catering": "team-building-appreciation",
    "tastings": "team-building-appreciation",
    "trust-team-building": "learning-development",
    "virtual-retreats": "offsites-retreats",
    "yoga-stretch": "health-wellness",
}

export const categoryOptions = [
    {
        "learning-development": {
            label: "Learning & Development",
            options: [
                { 
                    key: "leadership-development",
                    label: "Leadership Development"
                },
                { 
                    key: "trust-team-building",
                    label: "Trust & Team Building"
                },
                { 
                    key: "productivity",
                    label: "Productivity"
                },
                { 
                    key: "communication",
                    label: "Communication"
                },
                { 
                    key: "diversity-equity-and-inclusion",
                    label: "Diversity, Equity, and Inclusion"
                },
                { 
                    key: "personal-development",
                    label: "Personal Development"
                },
                { 
                    key: "keynote-speakers",
                    label: "Keynote Speakers"
                },
                { 
                    key: "life-skills",
                    label: "Life Skills"
                },
                { 
                    key: "organizational-development",
                    label: "Organizational Development"
                },
            ],
        }
    },
    {
        "health-wellness": {
            label: "Health and Wellness",
            options: [
                { 
                    key: "health",
                    label: "Health"
                },
                { 
                    key: "fitness",
                    label: "Fitness"
                },
                { 
                    key: "breathwork",
                    label: "Breathwork"
                },
                { 
                    key: "nutrition",
                    label: "Nutrition"
                },
                {
                    key: 'yoga-stretch',
                    label: 'Yoga & Stretch',
                },
                {
                    key: 'meditation-mindfulness',
                    label: 'Meditation & Mindfulness',
                },
                {
                    key: 'personal-care',
                    label: 'Personal Care',
                },
            ]
        }
    },
    {
        "team-building-appreciation": {
            label: "Team Building & Appreciation",
            options: [
                {
                    key: 'games',
                    label: 'Games',
                },
                {
                    key: 'tastings',
                    label: 'Tastings',
                },
                {
                    key: 'cooking-classes',
                    label: 'Cooking Classes',
                },
                {
                    key: 'creative',
                    label: 'Creative',
                },
                {
                    key: 'event',
                    label: 'Events',
                },
                {
                    key: 'gifts-celebrations',
                    label: 'Gifts & Celebrations',
                },
                {
                    key: 'social-responsibility',
                    label: 'Social Responsibility',
                },
                {
                    key: 'specialty-catering',
                    label: 'Specialty Catering',
                },
            ],
        }
    },
    {
        "offsites-retreats": {
            label: "Offsites & Retreats",
            options: [
                {
                    key: 'virtual-retreats',
                    label: 'Virtual Retreats',
                },
                {
                    key: 'los-angeles-offsites',
                    label: 'Los Angeles Offsites',
                },
                {
                    key: 'las-vegas-offsites',
                    label: 'Las Vegas Offsites',
                },
                {
                    key: 'executive-retreats',
                    label: 'Executive Retreats',
                },
            ],
        }
    }
]

export const tagOptions = [
    {
        "tag1": {
            label: null,
            options: [
                {
                    key: 'emotional-intelligence',
                    label: 'Emotional Intelligence',
                },
                {
                    key: 'stress-management',
                    label: 'Stress Management',
                },
                {
                    key: 'communication',
                    label: 'Communication',
                },
                {
                    key: 'public-speaking',
                    label: 'Public Speaking',
                },
                {
                    key: 'feedback',
                    label: 'Feedback',
                },
            ]
        }
    },
    {
        "tag2": {
            label: null,
            options: [
                {
                    key: 'creative-thinking',
                    label: 'Creative Thinking',
                },
                {
                    key: 'problem-solving',
                    label: 'Problem Solving',
                },
                {
                    key: 'engagement',
                    label: 'Engagement',
                },
                {
                    key: 'productivity',
                    label: 'Productivity',
                },
                {
                    key: 'knowledge',
                    label: 'Knowledge',
                },
            ]
        }
    },
    {
        "tag3": {
            label: null,
            options: [
                {
                    key: 'motivation',
                    label: 'Motivation',
                },
                {
                    key: 'wellness',
                    label: 'Wellness',
                },
                {
                    key: 'body-mobility',
                    label: 'Body Mobility',
                },
                {
                    key: 'performance',
                    label: 'Performance',
                },
                {
                    key: 'mental-health',
                    label: 'Mental Health',
                },
            ]
        }
    },
    {
        "tag4": {
            label: null,
            options: [
                {
                    key: 'trust-building',
                    label: 'Trust Building',
                },
                {
                    key: 'community',
                    label: 'Community',
                },
                {
                    key: 'empowerment',
                    label: 'Empowerment',
                },
                {
                    key: 'cultural-education',
                    label: 'Cultural Education',
                },
                {
                    key: 'employee-appreciation',
                    label: 'Employee Appreciation',
                },
            ]
        }
    },   
]