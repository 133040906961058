import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';


import css from './SectionHowItWorks.css';
import step1 from './assets/1.png';
import step2 from './assets/2.png';
import step3 from './assets/3.png';
import step4 from './assets/4.png';
import line from './assets/Line.png';
import line2 from './assets/Line2.png';
import line3 from './assets/Line3.png';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const Steps = (image) => {
    return(
        <div className={css.howItWorksContainer}>
          <img src={image.image}/>
          <div className={css.captionContainer}>
            <div className={css.stepProcess}>
              <FormattedMessage id={image.caption}/>
            </div>
            <div className={css.stepNum}>
              <FormattedMessage id={image.step}/>
            </div>
          </div>
        </div>
    );
  }

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorks.howItWorks" />
      </div>
      <div className={css.stepsWrapper}>
        <div className={css.step1}>
          <Steps image={step1} caption="SectionHowItWorks.step1" step="SectionHowItWorks.one" />
        </div>
        <div className={css.step2}>
          <img src={line} className={css.line}/>
          <Steps image={step2} caption="SectionHowItWorks.step2" step="SectionHowItWorks.two"/>
        </div>
        <div className={css.step3}>
          <img src={line2} className={css.line2}/>
          <Steps image={step3} caption="SectionHowItWorks.step3" step="SectionHowItWorks.three"/>
        </div>
        <div className={css.step4}>
          <img src={line3} className={css.line3}/>
          <Steps image={step4} caption="SectionHowItWorks.step4" step="SectionHowItWorks.four"/>
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
