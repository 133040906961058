import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { filterKeys, processTags } from '../../util/htmlBuilder';
import { formNames } from '../../resources/forms';
import { EditListingOutcomesForm } from '../../forms';
import config from '../../config';

import css from './EditListingOutcomesPanel.css';

const formName = formNames["outcomes"];

const EditListingOutcomesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingOutcomesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingOutcomesPanel.createListingTitle" />
  );

  // console.log(publicData);

  const tags = publicData && publicData.tags;

  // console.log(tags);

  /*
  set up initial values here
  */

  const initialValuesObject = { tags };


  const categoryOptions = findOptionsForSelectFilter('categories', config.custom.filters);

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingOutcomesForm
        className={css.form}
        initialValues={initialValuesObject}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          // console.log(values);
          const { tags = [] } = values;

          const newTags = processTags(tags);

          const updateValues = {
            publicData: { 
              tags: newTags
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={categoryOptions}
        tags={publicData.tags}
      />
    </div>
  );
};

EditListingOutcomesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingOutcomesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingOutcomesPanel;
