import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput } from '../../components';
// import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';

import css from './EditListingBusinessBioForm.css';

const TITLE_MAX_LENGTH = 60;

const EditListingBusinessBioFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        categories,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const aboutMessage = intl.formatMessage({
        id: 'EditListingBusinessBioForm.about',
      });
      const aboutPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBusinessBioForm.aboutPlaceholder',
      });

      // const aboutPlaceholderMessage = "The Big Quiz Thing is America’s premier provider of corporate events since 2002. The Big Quiz Thing brings customized trivia games straight to your team. In office high-rises and remote work environments we feature the finest in quirky, custom-written quiz content and trivia puzzles.";

      const aboutRequiredMessage = intl.formatMessage({
        id: 'EditListingBusinessBioForm.aboutRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingBusinessBioForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingBusinessBioForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingBusinessBioForm.showListingFailed" />
        </p>
      ) : null;

      const instagramLinkPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBusinessBioForm.instagramLinkPlaceholder',
      });

      const facebookLinkPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBusinessBioForm.facebookLinkPlaceholder',
      });

      const twitterLinkPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBusinessBioForm.twitterLinkPlaceholder',
      });

      const linkedinPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBusinessBioForm.linkedinPlaceholder',
      });

      const socialMediaLabel = intl.formatMessage({
        id: 'EditListingBusinessBioForm.socialMediaLabel',
      });

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <FieldTextInput
            id="businessAbout"
            name="businessAbout"
            className={css.description}
            type="textarea"
            label={aboutMessage}
            placeholder={aboutPlaceholderMessage}
            validate={composeValidators(required(aboutRequiredMessage))}
            autoFocus
            rows={3}
          />

          <FieldTextInput
            id="facebookLink"
            name="facebookLink"
            className={css.title}
            type="text"
            label={socialMediaLabel}
            placeholder={facebookLinkPlaceholderMessage}
            // maxLength={TITLE_MAX_LENGTH}
            // validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          />

          <FieldTextInput
            id="twitterLink"
            name="twitterLink"
            className={css.title}
            type="text"
            label=""
            placeholder={twitterLinkPlaceholderMessage}
            // maxLength={TITLE_MAX_LENGTH}
            // validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          />


          <FieldTextInput
            id="instagramLink"
            name="instagramLink"
            className={css.title}
            type="text"
            label=""
            placeholder={instagramLinkPlaceholderMessage}
            // maxLength={TITLE_MAX_LENGTH}
            // validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          />


          <FieldTextInput
            id="linkedinLink"
            name="linkedinLink"
            className={css.title}
            type="text"
            label=""
            placeholder={linkedinPlaceholderMessage}
            // maxLength={TITLE_MAX_LENGTH}
            // validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingBusinessBioFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingBusinessBioFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingBusinessBioFormComponent);
