import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { IconEnquiry, Modal } from '../../components';
import { NewOfferForm } from '../../forms';

import css from './NewOfferModal.css';
import { formatMoney } from '../../util/currency';

const NewOfferModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmitOffer,
    offerSent,
    negotiateOfferInProgress,
    negotiateOfferError,
    listing,
    currentOffer,
    initialQuote,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'ReviewModal.later' });
  const listingTitle = <span className={css.listingTitle}>{listing}</span>;
  const offer = currentOffer ? formatMoney(intl, currentOffer) : null;

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <IconEnquiry className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id={initialQuote ? "NewOfferModal.titleInitial" : "NewOfferModal.title"} values={{ listingTitle }} />
      </p>
      <p className={css.modalMessage}>
      <FormattedMessage id={initialQuote ? "NewOfferModal.descriptionInitial" : "NewOfferModal.description"} />
      </p>
      {offer && (
        <div className={css.modalOffer}>
          <p className={css.modalLabel}>
            <FormattedMessage id="NewOfferModal.currentOffer" />
          </p>
          <p className={css.modalTitle}>{offer}</p>
        </div>
      )}

      <NewOfferForm
        onSubmit={onSubmitOffer}
        offerSent={offerSent}
        negotiateOfferInProgress={negotiateOfferInProgress}
        negotiateOfferError={negotiateOfferError}
        currentOffer={currentOffer}
        initialQuote={initialQuote}
      />
    </Modal>
  );
};

const { bool, string } = PropTypes;

NewOfferModal.defaultProps = {
  className: null,
  rootClassName: null,
  offerSent: false,
  negotiateOfferInProgress: false,
  negotiateOfferError: null,
  currentOffer: null,
};

NewOfferModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  offerSent: bool,
  negotiateOfferInProgress: bool,
  negotiateOfferError: propTypes.error,
  listing: string.isRequired,
  currentOffer: propTypes.money,
  initialQuote: bool.isRequired,
};

export default injectIntl(NewOfferModal);
