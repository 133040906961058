import React from 'react';
import PropTypes, { bool } from 'prop-types';
import classNames from 'classnames';

import config from '../../config';
import MobileLogoImage from './revive-desktop-logo.png';
import DesktopLogoImage from './revive-desktop-logo.png';
import DesktopLogoImageWhite from './revive-desktop-logo-white.png';
import css from './Logo.css';

const Logo = props => {
  const { className, format, useWhite, ...rest } = props;
  const isMobile = format !== 'desktop';
  const classes = classNames(className, { [css.logoMobile]: isMobile });
  const logoImage = isMobile ? MobileLogoImage : (useWhite ? DesktopLogoImageWhite :DesktopLogoImage);

  return (
    <img
      className={classes}
      src={logoImage}
      alt={config.siteTitle}
      {...rest}
    />
  );
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
  useWhite: false,
};

Logo.propTypes = {
  className: string,
  useWhite: bool,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
