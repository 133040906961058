import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '..';
import groupImage from './assets/corporatepartners-group.png';
import girlImage from './assets/corporatepartners-girl.png';
import guyImage from './assets/corporatepartners-guy.png';

import css from './SectionCorporateHero.css';

const SectionCorporateHero = props => {
  const { rootClassName, className, isMobileLayout } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.textContent}>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionCorporateHero.title" />
          </h1>
          <p>
          Revive helps corporate partners plan, develop, and bring to life curated workplace experiences. We have a community of validated vendors that are experts in workplace performance, well-being, and team building.  
          </p>
          <p>
            As the future of the workplace continues to evolve, it is important to support our teams both virtually and in-person. 
          </p>
          <p>
          Let's work together and invest in what matters most: people!
          </p>
          <NamedLink
            name="SearchPage"
            className={css.heroButton}
          >
            <FormattedMessage id="SectionCorporateHero.browseButton" />
          </NamedLink>
        </div>
        <div className={css.imageContainer}>
          <div className={css.groupImage}>
            <img src={groupImage} className={css.imageBackground} />
          </div>
          <div className={css.imageRow}>
            <div className={css.guyImage}>
              <img src={guyImage} className={css.imageBackground} />
            </div>
            <div className={css.girlImage}>
              <img src={girlImage} className={css.imageBackground} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionCorporateHero.defaultProps = { rootClassName: null, className: null };

SectionCorporateHero.propTypes = {
  rootClassName: string,
  className: string,
  isMobileLayout: bool.isRequired,
};

export default SectionCorporateHero;
