/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  // {
  //   id: 'dates',
  //   label: 'Dates',
  //   type: 'BookingDateRangeFilter',
  //   group: 'primary',
  //   // Note: BookingDateRangeFilter is fixed filter,
  //   // you can't change "queryParamNames: ['dates'],"
  //   queryParamNames: ['dates'],
  //   config: {},
  // },
  // {
  //   id: 'price',
  //   label: 'Price',
  //   type: 'PriceFilter',
  //   group: 'primary',
  //   // Note: PriceFilter is fixed filter,
  //   // you can't change "queryParamNames: ['price'],"
  //   queryParamNames: ['price'],
  //   // Price filter configuration
  //   // Note: unlike most prices this is not handled in subunits
  //   config: {
  //     min: 0,
  //     max: 1000,
  //     step: 5,
  //   },
  // },
  
  {
    id: 'location',
    label: 'Location Type',
    type: 'TabFilter',
    group: 'primary',
    queryParamNames: ['pub_locationType'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'virtual',
          label: 'Virtual',
          description: 'conducted on a web conference platform',
        },
        {
          key: 'onsite',
          label: 'Onsite',
          description: 'location is provided by the customer',
        },
        {
          key: 'offsite',
          label: 'Offsite',
          description: 'at the Experience Partner\'s location',
        },
      ],
    },
  },
  {
    id: 'mainCategories',
    label: 'Category',
    type: 'TabFilter',
    group: 'primary',
    queryParamNames: ['pub_categories'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'health-wellness',
          label: 'Health & Wellness',
        },
        {
          key: 'learning-development',
          label: 'Learning & Development',
        },
        {
          key: 'offsites-retreats',
          label: 'Offsites & Retreats',
        },
        {
          key: 'team-building-appreciation',
          label: 'Team Building & Appreciation',
        },
      ],
    },
  },
  {
    id: 'categories',
    label: 'Categories',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_categories'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      segmented: true,

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'health-wellness',
          label: 'Health & Wellness',
        },
        {
          key: 'breathwork',
          label: 'Breathwork',
          parent: 'health-wellness',
        },
        {
          key: 'fitness',
          label: 'Fitness',
          parent: 'health-wellness',
        },
        {
          key: 'health',
          label: 'Health',
          parent: 'health-wellness',
        },
        {
          key: 'meditation-mindfulness',
          label: 'Meditation & Mindfulness',
          parent: 'health-wellness',
        },
        {
          key: 'nutrition',
          label: 'Nutrition',
          parent: 'health-wellness',
        },
        {
          key: 'personal-care',
          label: 'Personal Care',
          parent: 'health-wellness',
        },
        {
          key: 'yoga-stretch',
          label: 'Yoga & Stretch',
          parent: 'health-wellness',
        },
        {
          key: 'learning-development',
          label: 'Learning & Development',
        },
        {
          key: 'communication',
          label: 'Communication',
          parent: 'learning-development',
        },
        {
          key: 'diversity-equity-and-inclusion',
          label: 'Diversity, Equity, and Inclusion',
          parent: 'learning-development',
        },
        {
          key: 'keynote-speakers',
          label: 'Keynote Speakers',
          parent: 'learning-development',
        },
        {
          key: 'leadership-development',
          label: 'Leadership Development',
          parent: 'learning-development',
        },
        {
          key: 'life-skills',
          label: 'Life Skills',
          parent: 'learning-development',
        },
        {
          key: 'organizational-development',
          label: 'Organizational Development',
          parent: 'learning-development',
        },
        {
          key: 'personal-development',
          label: 'Personal Development',
          parent: 'learning-development',
        },
        {
          key: 'productivity',
          label: 'Productivity',
          parent: 'learning-development',
        },
        {
          key: 'trust-team-building',
          label: 'Trust & Team Building',
          parent: 'learning-development',
        },
        {
          key: 'offsites-retreats',
          label: 'Offsites & Retreats',
        },
        {
          key: 'executive-retreats',
          label: 'Executive Retreats',
          parent: 'offsites-retreats',
        },
        {
          key: 'las-vegas-offsites',
          label: 'Las Vegas Offsites',
          parent: 'offsites-retreats',
        },
        {
          key: 'los-angeles-offsites',
          label: 'Los Angeles Offsites',
          parent: 'offsites-retreats',
        },
        {
          key: 'virtual-retreats',
          label: 'Virtual Retreats',
          parent: 'offsites-retreats',
        },
        {
          key: 'team-building-appreciation',
          label: 'Team Building & Appreciation',
        },
        {
          key: 'cooking-classes',
          label: 'Cooking Classes',
          parent: 'team-building-appreciation',
        },
        {
          key: 'creative',
          label: 'Creative',
          parent: 'team-building-appreciation',
        },
        {
          key: 'event',
          label: 'Events',
          parent: 'team-building-appreciation',
        },
        {
          key: 'games',
          label: 'Games',
          parent: 'team-building-appreciation',
        },
        {
          key: 'gifts-celebrations',
          label: 'Gifts & Celebrations',
          parent: 'team-building-appreciation',
        },
        {
          key: 'social-responsibility',
          label: 'Social Responsibility',
          parent: 'team-building-appreciation',
        },
        {
          key: 'specialty-catering',
          label: 'Specialty Catering',
          parent: 'team-building-appreciation',
        },
        {
          key: 'tastings',
          label: 'Tastings',
          parent: 'team-building-appreciation',
        },
      ],
    },
  },
  {
    id: 'outcomes',
    label: 'Outcomes',
    type: 'SelectMultipleFilter',
    group: 'primary',
    helpText: 'Filter by Outcomes You Aim to Achieve',
    queryParamNames: ['pub_tags'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      segmented: true,

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'tag1',
          label: '',
        },
        {
          key: 'tag2',
          label: '',
        },
        {
          key: 'tag3',
          label: '',
        },
        {
          key: 'tag4',
          label: '',
        },
        {
          key: 'body-mobility',
          label: 'Body Mobility',
          parent: 'tag3',
        },
        {
          key: 'communication',
          label: 'Communication',
          parent: 'tag1',
        },
        {
          key: 'community',
          label: 'Community',
          parent: 'tag4',
        },
        {
          key: 'creative-thinking',
          label: 'Creative Thinking',
          parent: 'tag2',
        },
        {
          key: 'cultural-education',
          label: 'Cultural Education',
          parent: 'tag4',
        },
        {
          key: 'emotional-intelligence',
          label: 'Emotional Intelligence',
          parent: 'tag1',
        },
        {
          key: 'employee-appreciation',
          label: 'Employee Appreciation',
          parent: 'tag4',
        },
        {
          key: 'empowerment',
          label: 'Empowerment',
          parent: 'tag4',
        },
        {
          key: 'engagement',
          label: 'Engagement',
          parent: 'tag2',
        },
        {
          key: 'feedback',
          label: 'Feedback',
          parent: 'tag1',
        },
        {
          key: 'knowledge',
          label: 'Knowledge',
          parent: 'tag2',
        },
        {
          key: 'mental-health',
          label: 'Mental Health',
          parent: 'tag3',
        },
        {
          key: 'motivation',
          label: 'Motivation',
          parent: 'tag3',
        },
        {
          key: 'performance',
          label: 'Performance',
          parent: 'tag3',
        },
        {
          key: 'problem-solving',
          label: 'Problem Solving',
          parent: 'tag2',
        },
        {
          key: 'productivity',
          label: 'Productivity',
          parent: 'tag2',
        },
        {
          key: 'public-speaking',
          label: 'Public Speaking',
          parent: 'tag1',
        },
        {
          key: 'stress-management',
          label: 'Stress Management',
          parent: 'tag1',
        },
        {
          key: 'trust-building',
          label: 'Trust Building',
          parent: 'tag4',
        },
        {
          key: 'wellness',
          label: 'Wellness',
          parent: 'tag3',
        },
      ],
    },
  },
  {
    id: 'keyword',
    label: 'Search',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    // { key: '-price', label: 'Lowest price' },
    // { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
