import React, { useEffect, useState } from 'react';
import PropTypes, { func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { sendEmail, setInitialValues } from '../../ducks/EmailSender.duck';
import {
  Page,
  SectionCompanies,
  SectionExperienceCategories,
  SectionCorporateHero,
  SectionCorporateBenefits,
  SectionFeaturedExperiences,
  SectionHowItWorks,
  SectionSearch,
  SectionScienceBacked,
  SectionContact,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/reviveFacebook-1200x630.jpg';
import twitterImage from '../../assets/reviveTwitter-600x314.jpg';
import css from './CorporatePartnersPage.css';
import { withViewport } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';

const MOBILE_BREAKPOINT = 767;

export const CorporatePartnersPageComponent = props => {
  const { 
    history,
    intl,
    location,
    scrollingDisabled,
    viewport,
    onSendEmail,
    sendEmailInProgress,
    sendEmailError,
    sendEmailSuccess,
    onResetForm,
  } = props;
  const [mounted, setMounted] = useState(false);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'CorporatePartnersPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'CorporatePartnersPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const isMobileLayout = viewport.width <= MOBILE_BREAKPOINT;

  useEffect(() => {
    if (!mounted) {
      onResetForm();
    }
    setMounted(true);
  });

  const onHandleCategoryClick = cat => {
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        {pub_categories: cat}
      )
    );
  }

  const handleFormSubmit = values => {
    onSendEmail(values);
  }

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionCorporateHero className={css.hero} history={history} location={location} isMobileLayout={isMobileLayout} />
          </div>
          <ul className={css.sections}>
            <li className={css.benefits}>
              <SectionCorporateBenefits />
            </li>
            <li className={css.contact}>
              <SectionContact
                intl={intl}
                onSubmit={handleFormSubmit}
                sendEmailInProgress={sendEmailInProgress}
                sendEmailError={sendEmailError} 
                sendEmailSuccess={sendEmailSuccess}
              />
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object, shape, number, error } = PropTypes;

CorporatePartnersPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  sendEmailInProgress: bool.isRequired,
  sendEmailError: propTypes.error,
  sendEmailSuccess: bool.isRequired,
  onSendEmail: func.isRequired,
  onResetForm: func.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

};

const mapStateToProps = state => {
  const {
    sendEmailInProgress,
    sendEmailError,
    sendEmailSuccess,
  } = state.EmailSender;
  return {
    sendEmailInProgress,
    sendEmailError,
    sendEmailSuccess,
    scrollingDisabled: isScrollingDisabled(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSendEmail: params => dispatch(sendEmail(params)),
    onResetForm: params => dispatch(setInitialValues(params)),
  }
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const CorporatePartnersPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport,
)(CorporatePartnersPageComponent);

export default CorporatePartnersPage;
