import React, { useEffect, useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { required, composeValidators, emailFormatValid } from '../../util/validators';
import { Form, IconSpinner, PrimaryButton, FieldTextInput, FieldPhoneNumberInput, } from '../../components';
import { Form as FinalForm } from 'react-final-form';

import css from './SectionContact.css';

const SectionContact = props => {

    const { intl, onSubmit, sendEmailError, sendEmailInProgress, sendEmailSuccess } = props;
    const [resetForm, onResetForm] = useState(false);
  
    useEffect(() => {
      if (sendEmailSuccess) {
        onResetForm(true);
      }
    }, [sendEmailSuccess]);

    const handleFormSubmit = values => {
      const { name, email, subject, phone, message } = values;
      const requiredMessage = intl.formatMessage({
        id: 'ContactPage.requiredField',
      });
      const invalidEmailMessage = intl.formatMessage({
        id: 'ContactPage.invalidEmail',
      });
      const errors = {
        name: required(requiredMessage)(name),
        email: required(invalidEmailMessage)(email),
        phone: required(requiredMessage)(phone),
        subject: required(requiredMessage)(subject),
        message: required(requiredMessage)(message),
      }

      const withErrors = Object.keys(errors).filter(key => errors[key] !== undefined);
      if (withErrors.length > 0) {
        return errors;
      }
      onSubmit(values);
    }
    const loadingSpinnerMaybe = sendEmailInProgress ? (
      <IconSpinner className={css.spinner} />
    ) : null;
  
    const error = sendEmailError ? (
      <p className={css.error}>
        <FormattedMessage id="ContactPage.sendMessageFailed" />
      </p>
    ) : null;
  
    const success = sendEmailSuccess ? (
      <p className={css.success}>
        <FormattedMessage id="ContactPage.sendMessageSuccess" />
      </p>
    ) : null;

    return (
        <div className={css.root}>
            <div className={css.titleContainer}>
                <FormattedMessage id="SectionContact.title" />
            </div>
            <div className={css.subtitleContainer}>
                <FormattedMessage id="SectionContact.subtitle" />
            </div>
            <FinalForm
                onSubmit={handleFormSubmit}
                render={fieldRenderProps => {
                  const {
                    handleSubmit,
                    form,
                  } = fieldRenderProps;
                  if (sendEmailSuccess && resetForm) {
                   
                    setTimeout(() => {
                      onResetForm(false);
                      form.restart(); 
                    }, 500);
                  }

                  const namePlaceholder = intl.formatMessage({ id: 'SectionContact.namePlaceholder' });
                  const nameLabel = intl.formatMessage({ id: 'SectionContact.name' });
                  const emailPlaceholder = intl.formatMessage({ id: 'SectionContact.emailPlaceholder' });
                  const emailLabel = intl.formatMessage({ id: 'SectionContact.email' });
                  const phonePlaceholder = intl.formatMessage({ id: 'SectionContact.phonePlaceholder' });
                  const phoneLabel = intl.formatMessage({ id: 'SectionContact.phone' });
                  const subjectPlaceholder = intl.formatMessage({ id: 'SectionContact.subjectPlaceholder' });
                  const subjectLabel = intl.formatMessage({ id: 'SectionContact.subject' });
                  const messagePlaceholder = intl.formatMessage({ id: 'SectionContact.messagePlaceholder' });
                  const messageLabel = intl.formatMessage({ id: 'SectionContact.message' });
                  

                  return (
                    <Form onSubmit={handleSubmit}>
                      <div className={css.formRow}>
                        <FieldTextInput
                          id="form-name"
                          name="name"
                          className={css.textField}
                          type="text"
                          label={nameLabel}
                          placeholder={namePlaceholder}
                          // validate={required(requiredMessage)}
                          // useMobileMargins
                        />
                        <FieldTextInput
                          id="form-email"
                          name="email"
                          className={css.textField}
                          type="email"
                          label={emailLabel}
                          placeholder={emailPlaceholder}
                          // validate={emailFormatValid(invalidEmailMessage)}
                          // useMobileMargins
                        />
                      </div>
                      <div className={css.formRow}>
                        <FieldPhoneNumberInput
                          id="form-phone"
                          name="phone"
                          className={css.textField}
                          type="text"
                          label={phoneLabel}
                          placeholder={phonePlaceholder}
                          // validate={required(requiredMessage)}
                          // useMobileMargins
                        />
                        <FieldTextInput
                          id="form-subject"
                          name="subject"
                          className={css.textField}
                          type="text"
                          label={subjectLabel}
                          placeholder={subjectPlaceholder}
                          // validate={required(requiredMessage)}
                          // useMobileMargins
                        />
                      </div>
                      <div className={css.formRow}>
                        <FieldTextInput
                        id="message"
                        name="message"
                        className={css.textArea}
                        type="textarea"
                        label={messageLabel}
                        placeholder={messagePlaceholder}
                        // validate={required(requiredMessage)}
                        rows={5}
                        />
                      </div>
                      <div className={css.submitButtonWrapper}>
                        <PrimaryButton type="submit" disabled={sendEmailInProgress}>
                          {loadingSpinnerMaybe}
                          {!sendEmailInProgress && <FormattedMessage id="ContactPage.sendMessage" />}
                        </PrimaryButton>
                      </div>
                      {error}
                      {success}
                    </Form>
                  );
                }}
            />
        </div>
    );
}

export default SectionContact;