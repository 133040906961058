/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, number } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { dateFromLocalToAPI } from '../../util/dates';
import { withViewport } from '../../util/contextHelpers';
import classNames from 'classnames';
import { withLastLocation } from 'react-router-last-location';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  IconArrowHead,
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { 
  IconAdd,
  IconClock,
  IconLocation,
  IconPriceTag,
  IconGroup,
  IconSocialMediaFacebook,
  IconSocialMediaTwitter,
  IconSocialMediaInstagram,
  IconSocialMediaLinkedIn, 
} from '../../components';

import {
  sendEnquiry,
  loadData,
  setInitialValues,
  fetchTransactionLineItems,
  requestQuote,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionBusinessAboutMaybe from './SectionBusinessAboutMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionHostMaybe from './SectionHostMaybe';
import SectionRulesMaybe from './SectionRulesMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionTagsMaybe from './SectionTagsMaybe';
import SectionSocialLinkMaybe from './SectionSocialLinkMaybe';
import SectionCustomSectionMaybe from './SectionCustomSectionMaybe';
import css from './ListingPage.css';
import last from 'lodash/last';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const MODAL_BREAKPOINT = 1023;

const { UUID } = sdkTypes;
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;


const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.gotoListing = this.gotoListing.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { currentUser, bookingData } = this.props;
    if(!prevProps.currentUser && currentUser && bookingData) {
      // submit booking data from previous request after authentication
      this.handleSubmitRequest(bookingData);
    }
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingDates, ...bookingData } = values;

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart: bookingDates.startDate,
        bookingEnd: bookingDates.endDate,
      },
      confirmPaymentError: null,
    };

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  handleSubmitRequest(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onRequestQuote,
      currentUser,
      location,
    } = this.props;


    const listingId = new UUID(params.id);
    const { price } = getListing(listingId).attributes;
    const { bookingDate, groupSize } = values;
    const bookingStart = dateFromLocalToAPI(bookingDate.date);

    // Adjust booking start based on time from UTC to match actual start date
    // if ( utcOffset < 0 ||  utcOffset > 720 ) {
    //   bookingStart = moment(bookingDate.date).startOf('day').add(1, 'day').toDate();
    // }
    // console.log('booking start local ::', bookingDate.date);
    // console.log('booking start api ::', bookingStart);
    // console.log('booking expiry utc ::', moment(bookingStart).subtract(1, 'day').toDate());
    const bookingData = {
      bookingStart,
      bookingEnd: moment(bookingStart).add(1, 'days').toDate(),
      negotiatedTotal: price || new Money(1, 'USD'), // Initialize customer offer the default unit price,
      metadata: { groupSize: groupSize && parseInt(groupSize) || 0 },
    };
      
    // console.log('bookingData', bookingData);

    const routes = routeConfiguration();

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before booking
      const { setInitialValues } = findRouteByRouteName('ListingPage', routes);
      // console.log('setInitialValues', setInitialValues);
      callSetInitialValues(setInitialValues, { bookingData: values });

      // console.log('Initialize ListingPage ::', { bookingData: values });
      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routes, {}, {}), state);
    } else {
      onRequestQuote(listingId, bookingData)
        .then(txId => {
  
          // Redirect to OrderDetailsPage
          history.push(
            createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
          );
        })
        .catch(() => {
          // Ignore, error handling in duck file
        });
    }
    


  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  gotoListing() {
    const { history, lastLocation } = this.props;
    const routes = routeConfiguration();
    if(lastLocation && lastLocation.pathname === '/s') {
      history.goBack();
    } else {
      history.push(
        createResourceLocatorString('SearchPage', routes, {}, {})
      );
    }

  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      requestQuoteInProgress,
      requestQuoteError,
      timeSlots,
      fetchTimeSlotsError,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      viewport,
      bookingData,
    } = this.props;

    const isMobileLayout = viewport.width <= MODAL_BREAKPOINT;
    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'title';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );
    const bookingSubTitle = intl.formatMessage({ id: 'ListingPage.bookingSubTitle' });

    const topbar = <TopbarContainer />;

    // console.log(publicData);

    const listingOwner = get(publicData, 'listing_owner', '');
    const businessAbout = get(publicData, 'businessAbout', '');
    const customPrice = get(publicData, 'price_per_hour', '');

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };

    const handleCategoryClick = cat => {
      const queryParams = { pub_categories: cat };
      const { history } = this.props;
      history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
    }
    const handleLocationClick = cat => {
      const queryParams = { pub_locationType: cat };
      const { history } = this.props;
      history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
    }
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    // const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));
    const showContactUser = false;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);


    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = publicData.listingOwner || userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmitRequest(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: customPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );
    const backLink = (
      <a className={css.backLink} onClick={this.gotoListing}>
        <IconArrowHead direction="left" size="big" rootClassName={css.arrowIcon} />
        <FormattedMessage id='ListingPage.backLinkTitle' />
      </a>
    );

    const amenityOptions = findOptionsForSelectFilter('amenities', filterConfig);
    const categoryOptions = findOptionsForSelectFilter('categories', filterConfig);
    const category =
      publicData && publicData.categories ? (
        <span className={css.categoriesSection} key='cat-0'>
          <span className={css.separator}>•</span>
          {publicData.categories.map(cat => (
            <span key={cat} onClick={() => handleCategoryClick(cat) }>
              <span className={css.categoryLink}>{categoryLabel(categoryOptions, cat)}</span>
              <span className={css.separator}>•</span>
            </span>
          ))}
        </span>
      ) : null;
    const locationOptions = findOptionsForSelectFilter('location', filterConfig);
    const locations =
      publicData && publicData.locationType ? (
        <span className={css.categoriesSection} key='loc-0'>
          {publicData.locationType.map(l => (
            <span key={l} onClick={() => handleLocationClick(l) }>
              <span className={css.categoryLink}>{categoryLabel(locationOptions, l)}</span>
              <span className={css.separator}>•</span>
            </span>
          ))}
        </span>
      ) : null;
    const durationSection = publicData && publicData.duration ? (
      <SectionDetailsMaybe 
        icon={<IconClock rootClassName={css.iconDetails} />} 
        text={publicData.duration} 
      />
    ): null;
    const locationTypes = publicData.locationType ? publicData.locationType.map(l => locationOptions.find(location => location.key === l)) : null;
    const locationSection = locationTypes ? (
        <SectionDetailsMaybe icon={<IconLocation rootClassName={css.iconDetails} />} >
          {locationTypes.map(l => <span key={l.key}>{`${l.label} - ${l.description}`}</span>)}
        </SectionDetailsMaybe>
      ): null;
      
    let groupSizeText = '';
    if (publicData && (publicData.minimumParticipants || publicData.maximumParticipants)) {
      if (publicData.minimumParticipants) {
        groupSizeText += publicData.minimumParticipants;
      } else {
        groupSizeText += '1';
      }
      if (publicData.maximumParticipants) {
        groupSizeText += ` to ${publicData.maximumParticipants}`;
      } else {
        groupSizeText += ' to any';
      }
    } else if(publicData && publicData.group_size) {
      groupSizeText = publicData.group_size;
    }
    const groupSizeSection = groupSizeText ? (
      <SectionDetailsMaybe icon={<IconGroup rootClassName={css.iconDetails} />} text={groupSizeText} />
    ): null;
    const clientsSection = publicData && publicData.client ? (
        <SectionDetailsMaybe text={intl.formatMessage({id: 'ListingPage.clients' })} moreText={publicData.client} />
      ): null;

    const priceSection = publicData && publicData.price_per_hour ? (
      <SectionDetailsMaybe 
        icon={<IconPriceTag rootClassName={css.iconDetails} />} 
        text={publicData.price_per_hour}
      />
    ): null;

    const socialSection = (
      <div>
        <p className={css.sectionTitle}>{intl.formatMessage({ id: 'ListingPage.social' })}</p>
      
        <div className={classNames(css.detailsSection, css.detailsSectionInline)}>
          <SectionSocialLinkMaybe
            icon={<IconSocialMediaFacebook rootClassName={css.iconSocial} />}
            // text={publicData && 'publicData.facebook_text'}
            link={publicData && publicData.facebook_link}
          />
          <SectionSocialLinkMaybe
            icon={<IconSocialMediaTwitter rootClassName={css.iconSocial} />}
            // text={publicData && publicData.twitter_text}
            link={publicData && publicData.twitter_link}
          />
          <SectionSocialLinkMaybe
            icon={<IconSocialMediaInstagram rootClassName={css.iconSocial} />}
            // text={publicData && publicData.instagram_text}
            link={publicData && publicData.instagram_link}
          />
          <SectionSocialLinkMaybe
            icon={<IconSocialMediaLinkedIn rootClassName={css.iconSocial} />}
            // text={publicData && publicData.linkedin_text}
            link={publicData && publicData.linkedin_link}
          />
        </div>
      </div>
    );

    const detailsSection = (
      <div className={css.detailsSection}>
        {durationSection}
        {locationSection}
        {priceSection}
        {groupSizeSection}
        {clientsSection}
        {publicData && (publicData.facebook_link || publicData.instagram_link || publicData.twitter_link || publicData.linkedin_link) &&
          socialSection}
      </div>
    );

    
    
    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              <div className={css.contentContainer}>
                <div className={css.mainContent}>
                  {!isMobileLayout && backLink}
                  <SectionImages
                    title={title}
                    listing={currentListing}
                    isOwnListing={isOwnListing}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingType,
                      tab: listingTab,
                    }}
                    imageCarouselOpen={this.state.imageCarouselOpen}
                    onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                    handleViewPhotosClick={handleViewPhotosClick}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                  {/* <SectionAvatar user={currentAuthor} params={params} /> */}
                  {isMobileLayout && backLink}
                  <SectionHeading
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    customPrice={customPrice}
                    richTitle={richTitle}
                    category={category}
                    locations={locations}
                    hostLink={hostLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                    listingHostName={listingOwner}
                  />
                  <SectionTagsMaybe tags={!!publicData && publicData.tags} />
                  {isMobileLayout && detailsSection}
                  <SectionBusinessAboutMaybe businessAbout={businessAbout} listingOwner={listingOwner} />
                  <SectionDescriptionMaybe description={description} />
                  {/* <SectionFeaturesMaybe options={categoryOptions} publicData={publicData} /> */}
                  <SectionRulesMaybe publicData={publicData} />
                  <SectionMapMaybe
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                  />
                  <SectionCustomSectionMaybe publicData={publicData} field="thingsToKnow" />
                  {/* <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} /> */}
                  {/* <SectionHostMaybe
                    title={title}
                    listing={currentListing}
                    authorDisplayName={authorDisplayName}
                    onContactUser={this.onContactUser}
                    isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                    onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    onSubmitEnquiry={this.onSubmitEnquiry}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                  /> */}
                </div>
                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  requestQuoteInProgress={requestQuoteInProgress}
                  requestQuoteError={requestQuoteError}
                  initialValues={bookingData}
                >
                  {!isMobileLayout && detailsSection}
                </BookingPanel>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
  requestQuoteError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  requestQuoteInProgress: bool.isRequired,
  requestQuoteError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onRequestQuote: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  
  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    requestQuoteInProgress,
    requestQuoteError,
    bookingData,
  } = state.ListingPage;
  
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    requestQuoteInProgress,
    requestQuoteError,
    bookingData,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onRequestQuote: (listingId, bookingData) => dispatch(requestQuote(listingId, bookingData)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  withLastLocation,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl,
  withViewport,
)(ListingPageComponent);

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;
