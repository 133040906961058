import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconLocation.css';

const IconLocation = props => {
  const { rootClassName, className, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} enableBackground="new 0 0 512 512" width={size} height={size} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      
      <g transform="scale(1.14 1), translate(-35, 0)">
        <g>
          <path className={css.marketplaceColorStroke} d="M341.476,338.285c54.483-85.493,47.634-74.827,49.204-77.056C410.516,233.251,421,200.322,421,166
            C421,74.98,347.139,0,256,0C165.158,0,91,74.832,91,166c0,34.3,10.704,68.091,31.19,96.446l48.332,75.84
            C118.847,346.227,31,369.892,31,422c0,18.995,12.398,46.065,71.462,67.159C143.704,503.888,198.231,512,256,512
            c108.025,0,225-30.472,225-90C481,369.883,393.256,346.243,341.476,338.285z M147.249,245.945
            c-0.165-0.258-0.337-0.51-0.517-0.758C129.685,221.735,121,193.941,121,166c0-75.018,60.406-136,135-136
            c74.439,0,135,61.009,135,136c0,27.986-8.521,54.837-24.646,77.671c-1.445,1.906,6.094-9.806-110.354,172.918L147.249,245.945z
            M256,482c-117.994,0-195-34.683-195-60c0-17.016,39.568-44.995,127.248-55.901l55.102,86.463
            c2.754,4.322,7.524,6.938,12.649,6.938s9.896-2.617,12.649-6.938l55.101-86.463C411.431,377.005,451,404.984,451,422
            C451,447.102,374.687,482,256,482z"/>
        </g>
        <g>
          <path className={css.marketplaceColorStroke} d="M256,91c-41.355,0-75,33.645-75,75s33.645,75,75,75c41.355,0,75-33.645,75-75S297.355,91,256,91z M256,211
            c-24.813,0-45-20.187-45-45s20.187-45,45-45s45,20.187,45,45S280.813,211,256,211z"/>
        </g>
      </g>
    </svg>
  );
};

IconLocation.defaultProps = {
  rootClassName: null,
  className: null,
  size: '30',
};

IconLocation.propTypes = {
  rootClassName: string,
  className: string,
  size: string,
};

export default IconLocation;
