import React from 'react';
// import Select from 'react-select';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators, requiredFieldArrayCheckboxMaxCountCategories } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSegmentedCheckboxGroup } from '../../components';
import { renderCheckboxes } from '../../util/htmlBuilder';
import { returnFilterObject } from '../../resources/filterKeys';
import { formNames, categoryOptions } from '../../resources/forms';
// import CustomCategorySearchableSelectFieldMaybe from './CustomCategorySearchableSelectFieldMaybe';

import css from './EditListingTitleForm.css';

const TITLE_MAX_LENGTH = 150;

let formName = formNames["categories"];

const optionsLabel = <h3>Experience Category</h3>;

// const optionsList = returnFilterObject("categories", true);

const optionsList=[];

const categoryOptionsList = categoryOptions;

const commonProps = {
  label: optionsLabel,
  options: optionsList,
  twoColumns: true,
  className: css.checkboxes,
};

const optionsProps = {
  name: formName,
  id: formName,
  optionsListObject: categoryOptionsList,
  flipColumns: true,
  ...commonProps,
};

const EditListingTitleFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        categories,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditListingTitleForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingTitleForm.titlePlaceholder',
      });
      // const titlePlaceholderMessage = "The Ultimate Trivia Night";
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingTitleForm.titleRequired',
      });
      const maxTwoCategoriesRequired = intl.formatMessage({
        id: 'EditListingTitleForm.maxTwoCategoriesRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingTitleForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      // const descriptionMessage = intl.formatMessage({
      //   id: 'EditListingTitleForm.description',
      // });
      // const descriptionPlaceholderMessage = intl.formatMessage({
      //   id: 'EditListingTitleForm.descriptionPlaceholder',
      // });
      // const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      // const descriptionRequiredMessage = intl.formatMessage({
      //   id: 'EditListingTitleForm.descriptionRequired',
      // });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingTitleForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingTitleForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingTitleForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          />

          {/* {renderCheckboxes("categories")} */}

          <FieldSegmentedCheckboxGroup 
            {...optionsProps} 
            validate={requiredFieldArrayCheckboxMaxCountCategories(maxTwoCategoriesRequired, 2)}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingTitleFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingTitleFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingTitleFormComponent);
