import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../components';

import css from './TopbarDesktop.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    hasTransparentHeader,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [showTransparent, setTransparent] = useState(hasTransparentHeader);
  
  const handleScroll = () => {
    setTransparent(hasTransparentHeader && window.scrollY === 0);
  };

  // adding and cleanning up de event listener
  useEffect(() => {
    setMounted(true);
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasTransparentHeader]);

  
  const styleToUse = showTransparent ? css.navBarWhite : css.navBar;
  const rootToUse = showTransparent ? css.rootTransparent : css.root;
  const ctaToUse = showTransparent ? css.navBarWhiteCTA : css.navBarCTA;

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const classes = classNames(rootClassName || rootToUse, className);

  const changeStyles = (prevBackgroundStyle, prevLinkStyle,
    newBackgroundStyle, newLinkStyle) => {
      document.getElementsByClassName(prevBackgroundStyle)[0].className = newBackgroundStyle;
      let domNode = document.getElementsByClassName(prevLinkStyle);
      let nodeContainer = [];
      for (let count = 0; count < domNode.length; ++count){
        nodeContainer[count] = domNode[count];
      }

      for (let count = 0; count < nodeContainer.length; ++count){
        nodeContainer[count].className = newLinkStyle;
      }
  };

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={styleToUse}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  var is_admin = false;

  if (currentUser && currentUser.attributes.profile.publicData.is_admin) {
    is_admin = currentUser.attributes.profile.publicData.is_admin;
  }

  var manageListingsContent = null;

  if (is_admin) {
    manageListingsContent = (
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    )
  } else {
    manageListingsContent = (
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    )
  }

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      {manageListingsContent}
    </Menu>
  ) : null;

  const signupLink = (
    <NamedLink name="SignupPage" className={`${styleToUse} ${ctaToUse}`}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={styleToUse}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  var newListingLink = null;

  const nonAdminOnboardingLink = (
    <NamedLink className={`${styleToUse} ${ctaToUse}`} name="SendOnboardingDataPage">
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.createListing" />
      </span>
    </NamedLink>
  );


  if (is_admin) {
    newListingLink = (
      <NamedLink className={`${styleToUse} ${ctaToUse}`} name="NewListingPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
    );
  }

  const virtualLink = (
    <NamedLink name="VirtualPage" className={styleToUse}>
      <span className={css.virtual}>
        <FormattedMessage id="TopbarDesktop.virtualLink" />
      </span>
    </NamedLink>
  );

  const contact = (
    <NamedLink name="ContactsPage" className={styleToUse}>
      <span className={css.contacts}>
        <FormattedMessage id="TopbarDesktop.contactsLink" />
      </span>
    </NamedLink>
  );

  const howItWorks = (
    <NamedLink name="HowItWorksPage" className={styleToUse}>
      <span className={css.howItWorks}>
        <FormattedMessage id="TopbarDesktop.howItWorksLink" />
      </span>
    </NamedLink>
  );

  const inPersonLink = (
    <NamedLink name="InPersonPage" className={styleToUse}>
      <span className={css.inPerson}>
        <FormattedMessage id="TopbarDesktop.inPersonLink" />
      </span>
    </NamedLink>
  );

  const viewExperiencesLink = (
    <NamedLink
      name="SearchPage"
      to={{
        search: 'pub_categories=has_any%3Ateam-building-appreciation'
      }}
      className={styleToUse}
    >
      <span className={css.viewExperiences}>
        <FormattedMessage id="TopbarDesktop.viewExperiences" />
      </span>
    </NamedLink>

  );

  const mainActionLink = isAuthenticatedOrJustHydrated ? newListingLink : null;

  const onBoardingWhileLogoutLink = isAuthenticatedOrJustHydrated ? null: nonAdminOnboardingLink;

  return (
    <nav className={classes}>
      <div className={css.logoContainer}>
        <NamedLink className={css.logoLink} name="LandingPage">
          <Logo
            format="desktop"
            className={css.logo}
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
            useWhite={showTransparent}
          />
        </NamedLink>
      </div>
        <div className={css.linkContainer}>
        {/* {virtualLink} */}
        {viewExperiencesLink}
        {/* {howItWorks} */}
        {contact}
        {onBoardingWhileLogoutLink}
        {mainActionLink}
        {inboxLink}
        {loginLink}
        {profileMenu}
        </div>
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  hasTransparentHeader: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  hasTransparentHeader: bool.isRequired,
};

export default TopbarDesktop;
