import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconClock.css';

const IconClock = props => {
  const { rootClassName, className, size } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} enableBackground="new 0 0 443.294 443.294" width={size} height={size} viewBox="0 0 443.294 443.294" xmlns="http://www.w3.org/2000/svg">
      <path className={css.marketplaceColorStroke} d="m221.647 0c-122.214 0-221.647 99.433-221.647 221.647s99.433 221.647 221.647 221.647 221.647-99.433 221.647-221.647-99.433-221.647-221.647-221.647zm0 415.588c-106.941 0-193.941-87-193.941-193.941s87-193.941 193.941-193.941 193.941 87 193.941 193.941-87 193.941-193.941 193.941z"/>
      <path className={css.marketplaceColorStroke} d="m235.5 83.118h-27.706v144.265l87.176 87.176 19.589-19.589-79.059-79.059z"/>
    </svg>
  );
};

IconClock.defaultProps = {
  rootClassName: null,
  className: null,
  size: '30',
};

IconClock.propTypes = {
  rootClassName: string,
  className: string,
  size: string,
};

export default IconClock;
