import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks, NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SearchResultsPanel.css';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';

const SearchResultsPanel = props => {
  const { className, rootClassName, listings, pagination, search, setActiveListing, filterConfig, changeQueryParams } = props;
  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const categoryOptions = findOptionsForSelectFilter('categories', filterConfig);
  const locationOptions = findOptionsForSelectFilter('location', filterConfig);
  const mainOptions = findOptionsForSelectFilter('mainCategories', filterConfig);

  const getOrderedListings = listings => {
    const selectedCats = listings.map(l => l.cat);
    const orderedCats = categoryOptions.filter(cat => selectedCats.includes(cat.key));
    return orderedCats.map(cat => listings.find(l => l.cat === cat.key ));
  }
  const ListingSection = props => {
    const { cat, listings, isSingleSection, paginationLinks } = props;
    const sectionCat = categoryOptions.find(c => c.key === cat);
    const mainCat = mainOptions.find(c => c.key === sectionCat.parent)
    const filterMode = 'has_any:';
    // console.log('cat', );
    if(listings.length === 0) {
      return null;
    }
    return (
      
      <div className={css.listingCardsSection}>
        <h3 className={css.sectionTitle}>{`${mainCat.label} - ${sectionCat.label}`}</h3>
        <div className={css.listingCards}>
          {listings.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              setActiveListing={setActiveListing}
              categoryOptions={categoryOptions}
              locationOptions={locationOptions}
            />
          ))}
        </div>
        {paginationLinks}
        <div className={css.buttonContainer}>
          <div 
            className={css.exploreButton}
            onClick={() => { 
              console.log('on click ::', { pub_categories: `${filterMode}${isSingleSection ? sectionCat.parent : sectionCat.key}` });
              changeQueryParams({ pub_categories: `${filterMode}${isSingleSection ? sectionCat.parent : sectionCat.key}` })
            } }
          >
              <FormattedMessage id="SearchResultsPanel.button" values={{ catLabel: isSingleSection ? mainCat.label : sectionCat.label }} />
          </div>
          {/* <NamedLink
            name="SearchPage"
            to={{
              search:
                `pub_categories=${filterMode}${isSingleSection ? sectionCat.parent : sectionCat.key}`,
            }}
            className={css.exploreButton}>
              <FormattedMessage id="SearchResultsPanel.button" values={{ catLabel: isSingleSection ? mainCat.label : sectionCat.label }} />
          </NamedLink> */}
        </div>
      </div>
    )
  }
  const orderedListings = !!listings ? getOrderedListings(listings) : [];
  return (
    <div className={classes}>
      {/* {listings.map(l => (
        <ListingCard
          className={css.listingCard}
          key={l.id.uuid}
          listing={l}
          renderSizes={cardRenderSizes}
          setActiveListing={setActiveListing}
          categoryOptions={categoryOptions}
        />
      ))} */}
      {!!listings && orderedListings.map(
        ({ cat, listings }) => 
          <ListingSection
            key={cat}
            cat={cat}
            listings={listings}
            isSingleSection={orderedListings.length === 1}
            paginationLinks={paginationLinks} 
          />
        )}
      {props.children}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  filterConfig: config.custom.filters,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  filterConfig: propTypes.filterConfig,
};

export default SearchResultsPanel;
