import React, { useEffect, useState } from 'react';
import config from '../../config';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { Form, IconSpinner, PrimaryButton, FieldTextInput, FieldPhoneNumberInput, } from '../../components';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import {
  NamedLink,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';
import { required, composeValidators, emailFormatValid } from '../../util/validators';
import { sendEmail, setInitialValues } from '../../ducks/EmailSender.duck';
import PropTypes, { func, object } from 'prop-types';


import css from './CustomerInformationPage.css';
import { propTypes } from '../../util/types';

const CustomerInformationPageComponent = (props) => {
  const { intl, onSendEmail, sendEmailInProgress, sendEmailError, sendEmailSuccess, onResetForm } = props;
  const [state, setState] = useState({
    mounted: false,
    resetForm: false,
  });

  const handleFormSubmit = values => {
    const { name, email, company, phone, experienceName } = values;
    const requiredMessage = intl.formatMessage({
      id: 'CustomerInformationPage.requiredField',
    });
    const invalidEmailMessage = intl.formatMessage({
      id: 'CustomerInformationPage.invalidEmail',
    });
    const errors = {
      name: required(requiredMessage)(name),
      email: required(invalidEmailMessage)(email),
      // phone: required(requiredMessage)(phone),
      // company: required(requiredMessage)(company),
      // experienceName: required(requiredMessage)(experienceName),
    }

    const withErrors = Object.keys(errors).filter(key => errors[key] !== undefined);
    if (withErrors.length > 0) {
      return errors;
    }
    
    onSendEmail({ 
      name,
      email,
      phone,
      subject: "Customer Information",
      emailSubjectCustom: "New Customer Information form submitted",
      message: `<span><strong>Company:</strong> ${company}</span><br/><span><strong>Experience Name:</strong> ${experienceName}</span>`,
    });
  }

  useEffect(() => {
    if (!state.mounted) {
      setState({ ...state, mounted: true });
      onResetForm();
    }
    if (sendEmailSuccess) {
      setState({ ...state, resetForm: true });
    }
  }, [sendEmailSuccess]);

  const loadingSpinnerMaybe = sendEmailInProgress ? (
    <IconSpinner className={css.spinner} />
  ) : null;

  const error = sendEmailError ? (
    <p className={css.error}>
      <FormattedMessage id="CustomerInformationPage.sendMessageFailed" />
    </p>
  ) : null;

  const success = sendEmailSuccess ? (
    <p className={css.success}>
      <FormattedMessage id="CustomerInformationPage.sendMessageSuccess" />
    </p>
  ) : null;
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'CustomerInformationPage.schemaTitle' }, { siteTitle });

  // prettier-ignore
  return (
    <StaticPage
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'CustomerInformationPage',
        description: 'Send us a message',
        name: 'Contact Us',
      }}
    >
      <LayoutSingleColumn rootClassName={css.root}>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>

          <div className={css.contentWrapper}>
            <div className={css.contentSide}>
              <div className={css.textContent}>
                <h1 className={css.heroMainTitle}>
                  <FormattedMessage id="CustomerInformationPage.title" />
                </h1>
                <p>
                Revive helps corporate partners plan, develop, and bring to life curated workplace experiences. We have a community of validated vendors that are experts in workplace performance, well-being, and team building.  
                </p>
                <p>
                  As the future of the workplace continues to evolve, it is important to support our teams both virtually and in-person. 
                </p>
                <p>
                Let's work together and invest in what matters most: people!
                </p>
              </div>
            </div>

            <div className={css.contentMain}>
              <h1 className={css.pageTitle}><FormattedMessage id="CustomerInformationPage.formTitle" /></h1>
              
              <FinalForm
                onSubmit={handleFormSubmit}
                render={fieldRenderProps => {
                  const {
                    handleSubmit,
                    form,
                  } = fieldRenderProps;
                  
                  // const requiredMessage = intl.formatMessage({
                  //   id: 'CustomerInformationPage.requiredField',
                  // });
                  // const invalidEmailMessage = intl.formatMessage({
                  //   id: 'CustomerInformationPage.invalidEmail',
                  // });
                  if (sendEmailSuccess && state.resetForm) {
                    setTimeout(() => {  
                      setState({ ...state, resetForm: false });
                      form.restart(); 
                    }, 500);
                  }

                  const namePlaceholder = intl.formatMessage({ id: 'CustomerInformationPage.namePlaceholder' });
                  const nameLabel = intl.formatMessage({ id: 'CustomerInformationPage.name' });
                  const emailPlaceholder = intl.formatMessage({ id: 'CustomerInformationPage.emailPlaceholder' });
                  const emailLabel = intl.formatMessage({ id: 'CustomerInformationPage.email' });
                  const phonePlaceholder = intl.formatMessage({ id: 'CustomerInformationPage.phonePlaceholder' });
                  const phoneLabel = intl.formatMessage({ id: 'CustomerInformationPage.phone' });
                  const companyPlaceholder = intl.formatMessage({ id: 'CustomerInformationPage.companyPlaceholder' });
                  const companyLabel = intl.formatMessage({ id: 'CustomerInformationPage.company' });
                  const experienceNamePlaceholder = intl.formatMessage({ id: 'CustomerInformationPage.experienceNamePlaceholder' });
                  const experienceNameLabel = intl.formatMessage({ id: 'CustomerInformationPage.experienceName' });

                  return (
                    <Form onSubmit={handleSubmit}>
                      <div className={css.formRow}>
                        <FieldTextInput
                          id="form-name"
                          name="name"
                          className={css.textField}
                          type="text"
                          label={nameLabel}
                          placeholder={namePlaceholder}
                        />
                      </div>
                      <div className={css.formRow}>
                        <FieldTextInput
                          id="form-email"
                          name="email"
                          className={css.textField}
                          type="email"
                          label={emailLabel}
                          placeholder={emailPlaceholder}
                        />
                      </div>
                      <div className={css.formRow}>
                        <FieldPhoneNumberInput
                          id="form-phone"
                          name="phone"
                          className={css.textField}
                          type="text"
                          label={phoneLabel}
                          placeholder={phonePlaceholder}
                        />
                      </div>
                      <div className={css.formRow}>
                        <FieldTextInput
                          id="form-name"
                          name="company"
                          className={css.textField}
                          type="text"
                          label={companyLabel}
                          placeholder={companyPlaceholder}
                        />
                      </div>
                      <div className={css.formRow}>
                        <FieldTextInput
                        id="experienceName"
                        name="experienceName"
                        className={css.textField}
                        type="text"
                        label={experienceNameLabel}
                        placeholder={experienceNamePlaceholder}
                        />
                      </div>
                      <div className={css.submitButtonWrapper}>
                        <PrimaryButton type="submit" disabled={sendEmailInProgress}>
                          {loadingSpinnerMaybe}
                          {!sendEmailInProgress && <FormattedMessage id="CustomerInformationPage.sendMessage" />}
                        </PrimaryButton>
                      </div>
                      {error}
                      {success}
                    </Form>
                  );
                }}
              />
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};
const { bool } = PropTypes;

CustomerInformationPageComponent.defaultProps = {
  currentLocation: null,
};

CustomerInformationPageComponent.propTypes = {
  sendEmailInProgress: bool.isRequired,
  sendEmailError: propTypes.error,
  sendEmailSuccess: bool.isRequired,
  onSendEmail: func.isRequired,
  onResetForm: func.isRequired,
  currentLocation: object,

  // from injectIntl
  intl: intlShape.isRequired,
};


const mapStateToProps = state => {
  const {
    sendEmailInProgress,
    sendEmailError,
    sendEmailSuccess,
  } = state.EmailSender;
  const {
    currentLocation
  } = state.Routing;

  return {
    sendEmailInProgress,
    sendEmailError,
    sendEmailSuccess,
    currentLocation,
  }
};
const mapDispatchToProps = dispatch => {
  return {
    onSendEmail: params => dispatch(sendEmail(params)),
    onResetForm: params => dispatch(setInitialValues(params)),
  }
};

const CustomerInformationPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(CustomerInformationPageComponent);

export default CustomerInformationPage;
