import React, { Component } from 'react';
import { bool, func, node, object, string, arrayOf, shape } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { OutsideClickHandler } from '../../components';
import { parseSelectFilterOptions } from '../../util/search';

import { FilterForm } from '../../forms';
import css from './TabFilter.css';


const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class TabFilterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };

    // this.handleChange = this.handleChange.bind(this);
    // this.handleClear = this.handleClear.bind(this);
  }

  // handleChange(values) {
  //   const { onSubmit } = this.props;
  //   onSubmit(values);
  // }

  // handleClear() {
  //   const { onSubmit, onClear } = this.props;

  //   if (onClear) {
  //     onClear();
  //   }

  //   onSubmit(null);
  // }

  getTabs = (initialValue) => {
    const { options, onSelect, queryParamNames } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    const selectedOptions = initialValue
      ? parseSelectFilterOptions(initialValue)
      : [];
    return (
      options.map(filter => (
        <button type="button" 
          key={filter.key}
          className={initialValue && selectedOptions.includes (filter.key) ? css.labelSelected : css.label } 
          onClick={() => onSelect( { [queryParamName]: initialValue && selectedOptions.includes (filter.key)
            ? null : `has_any:${filter.key}` })}
        >
          {filter.label}
        </button>
        )
      )
    );
  };
  handleBlur() {
  }

  render() {
    const {
      rootClassName,
      className,
      queryParamNames,
      id,
      label,
      isSelected,
      initialValues,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const initialValue =
      initialValues && initialValues[queryParamNames] ? initialValues[queryParamNames] : null;

    return (
    <OutsideClickHandler onOutsideClick={this.handleBlur}>
      <div className={classes}>
        {this.getTabs(initialValue)}
      </div>
    </OutsideClickHandler>
    );
  }
}

TabFilterComponent.defaultProps = {
  rootClassName: null,
  className: null,
  plainClassName: null,
  initialValues: null,
  keepDirtyOnReinitialize: false,
};

TabFilterComponent.propTypes = {
  rootClassName: string,
  className: string,
  plainClassName: string,
  onSelect: func.isRequired,
  initialValues: object,
  keepDirtyOnReinitialize: bool,
  queryParamNames: arrayOf(string).isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,

  // form injectIntl
  intl: intlShape.isRequired,
};

const TabFilter = injectIntl(TabFilterComponent);

export default TabFilter;
