import * as custom from '../marketplace-custom-config';

import { categoryParents } from '../resources/forms';
 
export const locationLabels = {
    "virtual": "Virtual - conducted on a web conference platform",
    "onsite": "Onsite - location is provided by the customer",
    "offsite": "Offsite - at the Experience Partner's location"
}

export const returnFilterObject = (type = "outcomes", labelReturn = false) => {
    const filtersObject = custom.filters;
    // console.log(filtersObject);

    let filterObjectTemp = null;

    for (var i = 0; i < filtersObject.length; i++) {
        const filterObject = filtersObject[i];
        // console.log(filterObject);

        if (filterObject["id"] === type) {
            filterObjectTemp = filterObject;
            break;
        }
    }

    // console.log(outcomesFilterObject);

    let filterList = {};

    let optionList = [];

    if (filterObjectTemp) {
        const selectedFilter = filterObjectTemp["config"]["options"];

        // console.log(selectedFilter);

        for (var k = 0; k < selectedFilter.length; k++) {
            if (!labelReturn) {
                if (type === "location") {
                    filterList[selectedFilter[k]["key"]] = locationLabels[selectedFilter[k]["key"]];
                } else {
                    filterList[selectedFilter[k]["key"]] = selectedFilter[k]["label"];
                }
            } else {
                let optionObject = {};
                if (type === "location") {
                    // filterList[selectedFilter[k]["key"]] = locationLabels[selectedFilter[k]["key"]];
                    optionObject["key"] = selectedFilter[k]["key"];
                    optionObject["label"] = locationLabels[selectedFilter[k]["key"]];
                } else {
                    // filterList[selectedFilter[k]["key"]] = selectedFilter[k]["label"];

                    let includeOption = true;
                    if  (type === "categories") {
                        if (categoryParents.includes(selectedFilter[k]["key"])) {
                            includeOption = false;
                        }
                    }
                    if (includeOption) {
                        optionObject["key"] = selectedFilter[k]["key"];
                        optionObject["label"] = selectedFilter[k]["label"];
                    }
                }

                if (Object.keys(optionObject).length > 0) {
                    optionList.push(optionObject);
                }
            }
        }
    }

    // console.log(outcomesTagsList);
    // console.log(optionList);

    if (!labelReturn) {
        return filterList;
    } else {
        return optionList;
    }
    
}