import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>

      <p>
        At Let’s Revive Us, Inc. (“Let’s Revive Us”) your right to privacy is a concern. The purpose of this Privacy Policy is to inform you of what kinds of information we may gather and how we may use that information in connection with the <a href="www.letsrevive.us">www.letsrevive.us</a> website (the “Site”).
      </p>

      <h2>Our Services</h2>
      <p>
        Let’s Revive Us, Inc. is a workforce development platform that utilizes curated experiences provided to our service partners to drive retention, engagement, and performance in the workplace. Let’s Revive Us is a Business-to-business (“B2B”) platform that allows companies to search for workplace experiences, book a workplace experience, and retrieve data based on the outcomes of these experiences.
      </p>

      <h2>Collection of Information</h2>
      <p>
        There are some areas on our Site in which we may request information from you, including:
      </p>
      <p>
        <ul>
          <li>Contact information, such as your name, address, phone number, email address</li>
          <li>Information about your business</li>
          <li>Payment information</li>
          <li>Photographs that you choose to provide</li>
          <li>Other content you choose to provide to promote yourself or your business</li>
          <li>Username and password that you may establish on the Site</li>
          <li>Your feedback and correspondence with us</li>
        </ul>
      </p>
      <p>
        We may also accept employment applications via <a href="https://angel.co/" target="_blank">angel.co</a>, in which case we will also request information about your work experience, education, languages spoken, and other information of the type included on a resume. We take reasonable steps to ensure that information that we receive from you is reliable for its intended use, accurate, complete and current, including providing mechanisms by which you can amend or update your information.
      </p>
      <p>
        We, our service providers, and our business partners may also collect certain information about the use of our Site by automated means, such as via cookies, web beacons, embedded scripts, and other technologies. We may also use web beacons in our HTML-formatted emails. A “cookie” is a text file that websites send to a visitor‘s computer or other Internet-connected device to uniquely identify the visitor’s browser or to store information or settings in the browser. A “web beacon,” also known as an Internet tag, pixel tag or clear GIF, is used to transmit information back to a web server. The information that may be collected automatically includes your computer or mobile device operating system type and version number, browser type, screen resolution, IP address, the website you visited before browsing to our Site, general location information such as city, state or geographic area; and information about your use of and actions on our Site, such as pages you viewed, how long you spent on a page, navigation paths between pages, information about your activity on a page, access times, and length of access. Our service providers and business partners may collect this type of information over time and across third-party websites.
      </p>
      
      <h2>Use of Information</h2>
      <p>
        We may use personal information to:
      </p>
      <p>
        <ul>
          <li>Communicate about the products and services we offer, and respond to requests, inquiries, comments, and suggestions</li>
          <li>Provide our products and services</li>
          <li>Analyze use of our products and services</li>
          <li>Operate, evaluate and improve our business, the Site, and other products and services we offer (including to research and develop new products and services)</li>
          <li>Establish and maintain an individual’s profile on the Site</li>
          <li>Administer surveys and other market research</li>
          <li>Analyze and enhance our communications and strategies (including by identifying when emails we send are read)</li>
          <li>Tailor the content we display in our communications and on the Site</li>
          <li>Customize our services to individuals’ preferences</li>
          <li>Facilitate our recruitment activities (such as processing employment applications, evaluating a job candidate for an employment activity, and monitoring recruiting statistics)</li>
          <li>Comply with legal requirements, judicial process, and our company policies</li>
          <li>Protect against, identify, investigate, and respond to fraud, illegal activity (such as incidents of hacking or misuse of the Site), and claims and other liabilities, including by enforcing the terms and conditions that govern the services we provide</li>
        </ul>
      </p>
      <p>
        We may also aggregate and/or de-identify any information that we collect, such that the information no longer identifies any specific individual. We may use, disclose and otherwise process such information for our own legitimate business purposes – including historical and statistical analysis about our customers, sales, traffic patterns and related site information, and business planning – without restriction.
      </p>
      
      <h2>Sharing Information</h2>
      <p>
        We may share personal information with:
      </p>
      <p>
        <ul>
          <li>Lets Revive Us’ subsidiaries and affiliates for the purposes identified in this Privacy Policy</li>
          <li>Service providers that perform services on our behalf, or partners with whom we may collaborate, in each case for the purposes described in this Privacy Policy. The types of service providers and partners with whom we may share personal information may include customer service and support providers, technology providers (including technology support, web hosting providers, and email and text communications providers), survey and market research providers, advertising and marketing partners, and analytics organizations</li>
          <li>Business partners whose goods or services may of interest to you</li>
          <li>Professional advisors, such as lawyers, bankers, auditors, and insurers, where necessary in the course of the professional services they provide to us</li>
        </ul>
      </p>
      <p>
        We may transfer the personal information we maintain in the event we sell or transfer all or a portion of our business or assets (or during the negotiations of any such sale or transfer). After such a sale or transfer, individuals may contact the recipient with any inquiries concerning their personal information.
      </p>
      <p>
        In addition, we may share personal information to comply with legal and regulatory requirements, and protect against and prevent fraud, illegal activity (such as identifying and responding to incidents of hacking or misuse of our Site), and claims and other liabilities.
      </p>

      <h2>Your Choices</h2>
      <p>
        <ul>
          <li>You may choose not to provide any or all of the information that is requested on the Site, but we may not be able to provide our products or services to you in that case. In the event that you no longer want us to process or utilize your User Information, please contact us.</li>
          <li>Some of the business partners that collect information about users’ activities on the Site may be members of organizations or programs that provide choices to individuals regarding the use of their browsing behavior for purposes of targeted advertising. Users may opt out of receiving targeted advertising on websites through participating members of the <a href="https://www.networkadvertising.org/choices" target="_blank">Network Advertising Initiative</a> and <a href="http://www.aboutads.info/choices" target="_blank">Digital Advertising Alliance</a>. Please note that we also may work with companies that offer their own opt-out mechanisms and may not participate in the opt-out mechanisms that we linked above. If you choose to opt-out of targeted advertisements, you will still see advertisements online but they may not be relevant to you. Even if you do choose to opt out, not all companies that serve online behavioral advertising are included in this list, and so you may still receive some cookies and tailored advertisements from companies that are not listed.</li>
          <li>Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We currently do not currently respond to do not track signals. To find out more about “Do Not Track,” please visit <a href="http://www.allaboutdnt.com/" target="_blank">http://www.allaboutdnt.com</a>.</li>
        </ul>
      </p>

      <h2>Security</h2>
      <p>
        We take appropriate measures to keep the personal information you disclose to us secure. However, we cannot guarantee the security of your information.
      </p>

      <h2>International Transfers</h2>
      <p>
        We may transfer personal information to countries other than the country in which the data was originally collected for the purposes described in this Privacy Policy. For example, we transfer the personal information of individuals located outside of the United States to the United States, where we are headquartered. The countries to which we transfer personal information may not have the same data protection laws as the country in which we initially collected the information.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may modify this Privacy Policy at any time. If we update or modify this Privacy Policy, we will post the changes on the Site. We may (and, where required by law, will) also provide notification of changes in another way that we believe is reasonably likely to reach you, such as via e-mail (if you have an account where we have your contact information) or another manner through the Site.
      </p>

      <h2>Questions and Comments</h2>
      <p>
        If you have any questions about this privacy policy, please contact us at hello@letsrevive.us or at our mailing address Let’s Revive Us, Inc., 1626 Montana Ave. Suite 606 Santa Monica, CA 90403.
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
