import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { renderCheckboxes } from '../../util/htmlBuilder';
import { requiredFieldArrayCheckbox } from '../../util/validators';
import { returnFilterObject } from '../../resources/filterKeys';
import { formNames } from '../../resources/forms';
import { maxLength, required, composeValidators, valueShouldBeNumber } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldCheckboxGroup } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';

import css from './EditListingDetailsForm.css';

const TITLE_MAX_LENGTH = 60;

let formName = formNames["location"];

const optionsLabel = "Locations";

const optionsList = returnFilterObject("location", true);

const commonProps = {
  label: optionsLabel,
  options: optionsList,
  className: css.checkboxes,
};

const optionsProps = {
  name: formName,
  id: formName,
  ...commonProps,
};

const EditListingDetailsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        categories,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      // const titleMessage = intl.formatMessage({ id: 'EditListingDetailsForm.title' });
      // const titlePlaceholderMessage = intl.formatMessage({
      //   id: 'EditListingDetailsForm.titlePlaceholder',
      // });
      // const titleRequiredMessage = intl.formatMessage({
      //   id: 'EditListingDetailsForm.titleRequired',
      // });
      // const maxLengthMessage = intl.formatMessage(
      //   { id: 'EditListingDetailsForm.maxLength' },
      //   {
      //     maxLength: TITLE_MAX_LENGTH,
      //   }
      // );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.duration',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.durationPlaceholder',
      });
      // const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.durationRequired',
      });

      const pricePerHourMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.pricePerHour',
      });
      const pricePerHourPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.pricePerHourPlaceholder',
      });
      // const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const pricePerHourRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.pricePerHourRequired',
      });


      const minimumNumberOfParticipants = intl.formatMessage({
        id: 'EditListingDetailsForm.minimumNumberOfParticipants',
      });

      const minimumNumberOfParticipantsPlaceholder = intl.formatMessage({
        id: 'EditListingDetailsForm.minimumNumberOfParticipantsPlaceholder',
      });

      const minimumNumberOfParticipantsRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.minimumNumberOfParticipantsRequiredMessage',
      });

      const maximumNumberOfParticipantsPlaceholder = intl.formatMessage({
        id: 'EditListingDetailsForm.maximumNumberOfParticipantsPlaceholder',
      });

      const clientLabel = intl.formatMessage({
        id: 'EditListingDetailsForm.clientLabel',
      });

      const clientPlaceholder = intl.formatMessage({
        id: 'EditListingDetailsForm.clientPlaceholder',
      });

      const requiredOneCheckboxTicked = intl.formatMessage({
        id: 'OnboardingForm.requiredOneCheckboxTicked',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <FieldTextInput
            id="duration"
            name="duration"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />

          <FieldTextInput
            id="price_per_hour"
            name="price_per_hour"
            className={css.description}
            type="textarea"
            label={pricePerHourMessage}
            placeholder={pricePerHourPlaceholderMessage}
            validate={composeValidators(required(pricePerHourRequiredMessage))}
          />

          <FieldTextInput
            id="minimumParticipants"
            name="minimumParticipants"
            className={css.description}
            type="text"
            label={minimumNumberOfParticipants}
            placeholder={minimumNumberOfParticipantsPlaceholder}
            // validate={composeValidators(valueShouldBeNumber(pricePerHourRequiredMessage))}
            validate={composeValidators(required(minimumNumberOfParticipantsRequiredMessage))}
          />

          <FieldTextInput
            id="maximumParticipants"
            name="maximumParticipants"
            className={css.description}
            type="text"
            // label={minimumNumberOfParticipants}
            placeholder={maximumNumberOfParticipantsPlaceholder}
            // validate={composeValidators(valueShouldBeNumber(pricePerHourRequiredMessage))}
          />

          {/* {renderCheckboxes("location")} */}

          <FieldCheckboxGroup 
            {...optionsProps}
            validate={requiredFieldArrayCheckbox(requiredOneCheckboxTicked)}
          />

          <FieldTextInput
            id="client"
            name="client"
            className={css.description}
            type="textarea"
            label={clientLabel}
            placeholder={clientPlaceholder}
            // validate={composeValidators(required(clientRequiredMessage))}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDetailsFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDetailsFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDetailsFormComponent);
