import React from 'react';
// import Select from 'react-select';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, 
  composeValidators, requiredFieldArrayCheckboxMaxCountCategories, 
  requiredFieldArrayCheckbox,
  emailFormatValid
} from '../../util/validators';
import { Form, Button, FieldTextInput, 
  FieldTextInputExpanded,
  FieldCheckboxGroup, FieldSegmentedCheckboxGroup, FieldPhoneNumberInput } from '../../components';
import { renderCheckboxes } from '../../util/htmlBuilder';
import { returnFilterObject } from '../../resources/filterKeys';
import { formNames, categoryOptions, tagOptions } from '../../resources/forms';
// import CustomCategorySearchableSelectFieldMaybe from './CustomCategorySearchableSelectFieldMaybe';

import css from './SendOnboardingDataForm.css';

const TITLE_MAX_LENGTH = 60;

let categoriesFormName = formNames["categories"];

const categoriesOptionsLabel = "Experience Category";

const categoryOptionsListEmpty = [];

const categoryOptionsList = categoryOptions;

const categoriesCommonProps = {
  label: categoriesOptionsLabel,
  options: categoryOptionsListEmpty,
  twoColumns: true,
  className: css.checkboxes,
};

const categoriesOptionsProps = {
  name: categoriesFormName,
  id: categoriesFormName,
  optionsListObject: categoryOptionsList,
  flipColumns: true,
  ...categoriesCommonProps,
};

// console.log(categoriesOptionsProps);

let outcomesFormName = formNames["outcomes"];

const outcomesOptionsLabel = "Outcomes (What people acheive through your program)";

const outcomesOptionsEmptyList = [];

const outcomesOptionsList = tagOptions;

const outcomesCommonProps = {
  label: outcomesOptionsLabel,
  options: outcomesOptionsEmptyList,
  twoColumns: true,
  className: css.checkboxes,
};

const outcomesOptionsProps = {
  name: outcomesFormName,
  id: outcomesFormName,
  optionsListObject: outcomesOptionsList,
  ...outcomesCommonProps,
};

let locationFormName = formNames["location"];

const locationOptionsLabel = "Locations";

const locationOptionsList = returnFilterObject("location", true);

const locationCommonProps = {
  label: locationOptionsLabel,
  options: locationOptionsList,
  className: css.checkboxes,
};

const locationOptionsProps = {
  name: locationFormName,
  id: locationFormName,
  ...locationCommonProps,
};

const SendOnboardingDataFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        categories,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        sendEmailInProgress,
        sendEmailSuccess,
        resetForm,
        resetOnboardingPage,
        form
      } = formRenderProps;

      // console.log(sendEmailSuccess);
      // console.log(resetForm);

      if (sendEmailSuccess && resetForm) {
        setTimeout(() => {  
          resetOnboardingPage();
          form.restart(); 
        }, 500);
      }

      const titleMessage = intl.formatMessage({ id: 'SendOnboardingDataForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingTitleForm.titlePlaceholder',
      });
      // const titlePlaceholderMessage = "The Ultimate Trivia Night";
      const titleRequiredMessage = intl.formatMessage({
        id: 'SendOnboardingDataForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'SendOnboardingDataForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const requiredOneCheckboxTicked = intl.formatMessage({
        id: 'OnboardingForm.requiredOneCheckboxTicked',
      });

      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      const aboutMessage = intl.formatMessage({
        id: 'EditListingBusinessBioForm.about',
      });

      const aboutPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBusinessBioForm.aboutPlaceholder',
      });

      // const aboutPlaceholderMessage = "The Big Quiz Thing is America’s premier provider of corporate events since 2002. The Big Quiz Thing brings customized trivia games straight to your team. In office high-rises and remote work environments we feature the finest in quirky, custom-written quiz content and trivia puzzles.";

      const aboutRequiredMessage = intl.formatMessage({
        id: 'EditListingBusinessBioForm.aboutRequired',
      });

      const socialMediaLabel = intl.formatMessage({
        id: 'EditListingBusinessBioForm.socialMediaLabel',
      });

      const instagramLinkPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBusinessBioForm.instagramLinkPlaceholder',
      });

      const facebookLinkPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBusinessBioForm.facebookLinkPlaceholder',
      });

      const twitterLinkPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBusinessBioForm.twitterLinkPlaceholder',
      });

      const linkedinPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBusinessBioForm.linkedinPlaceholder',
      });

      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });

      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });

      const durationMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.duration',
      });
      const durationPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.durationPlaceholder',
      });

      const durationRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.durationRequired',
      });

      const pricePerHourMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.pricePerHour',
      });
      const pricePerHourPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.pricePerHourPlaceholder',
      });
      // const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const pricePerHourRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.pricePerHourRequired',
      });

      const minimumNumberOfParticipants = intl.formatMessage({
        id: 'EditListingDetailsForm.minimumNumberOfParticipants',
      });

      const minimumNumberOfParticipantsPlaceholder = intl.formatMessage({
        id: 'EditListingDetailsForm.minimumNumberOfParticipantsPlaceholder',
      });

      const minimumNumberOfParticipantsRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.minimumNumberOfParticipantsRequiredMessage',
      });

      const maximumNumberOfParticipantsPlaceholder = intl.formatMessage({
        id: 'EditListingDetailsForm.maximumNumberOfParticipantsPlaceholder',
      });

      const thingsToKnowMessage = intl.formatMessage({
        id: 'EditListingOutcomesForm.thingsToKnow',
      });
      const thingsToKnowPlaceholderMessage = intl.formatMessage({
        id: 'EditListingOutcomesForm.thingsToKnowPlaceholder',
      });
      // const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const thingsToKnowRequiredMessage = intl.formatMessage({
        id: 'EditListingOutcomesForm.thingsToKnowRequired',
      });

      const clientLabel = intl.formatMessage({
        id: 'EditListingDetailsForm.clientLabel',
      });

      const clientPlaceholder = intl.formatMessage({
        id: 'EditListingDetailsForm.clientPlaceholder',
      });

      const requiredMessage = intl.formatMessage({
        id: 'ContactPage.requiredField',
      });
      const invalidEmailMessage = intl.formatMessage({
        id: 'ContactPage.invalidEmail',
      });

      const maxTwoCategoriesRequired = intl.formatMessage({
        id: 'EditListingTitleForm.maxTwoCategoriesRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="SendOnboardingDataForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="SendOnboardingDataForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="SendOnboardingDataForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || sendEmailInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <FieldTextInput
            id="name"
            name="name"
            className={css.textField}
            type="text"
            label="Name"
            placeholder="Your name"
            validate={required(requiredMessage)}
            // useMobileMargins
          />
          <FieldTextInput
            id="email"
            name="email"
            className={css.textField}
            type="email"
            label="Email"
            placeholder="Your email"
            validate={emailFormatValid(invalidEmailMessage)}
            // useMobileMargins
          />

          <FieldPhoneNumberInput
            id="phone"
            name="phone"
            className={css.textField}
            type="text"
            label="Phone"
            placeholder="Your phone number"
            validate={required(requiredMessage)}
            // useMobileMargins
          />

          <FieldTextInput
            id="company"
            name="company"
            className={css.textField}
            type="text"
            label="Company"
            placeholder="Your company name"
            validate={required(requiredMessage)}
            // useMobileMargins
          />

          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          />

          <FieldSegmentedCheckboxGroup 
            {...categoriesOptionsProps} 
            validate={requiredFieldArrayCheckboxMaxCountCategories(maxTwoCategoriesRequired, 2)}
          />

          <FieldTextInput
            id="businessAbout"
            name="businessAbout"
            className={css.description}
            type="textarea"
            label={aboutMessage}
            placeholder={aboutPlaceholderMessage}
            validate={composeValidators(required(aboutRequiredMessage))}
            rows={2}
          />

          <FieldTextInput
            id="facebookLink"
            name="facebookLink"
            className={css.title}
            type="text"
            label={socialMediaLabel}
            placeholder={facebookLinkPlaceholderMessage}
            // maxLength={TITLE_MAX_LENGTH}
            // validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          />

          <FieldTextInput
            id="twitterLink"
            name="twitterLink"
            className={css.title}
            type="text"
            label=""
            placeholder={twitterLinkPlaceholderMessage}
            // maxLength={TITLE_MAX_LENGTH}
            // validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          />


          <FieldTextInput
            id="instagramLink"
            name="instagramLink"
            className={css.title}
            type="text"
            label=""
            placeholder={instagramLinkPlaceholderMessage}
            // maxLength={TITLE_MAX_LENGTH}
            // validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          />


          <FieldTextInput
            id="linkedinLink"
            name="linkedinLink"
            className={css.title}
            type="text"
            label=""
            placeholder={linkedinPlaceholderMessage}
            // maxLength={TITLE_MAX_LENGTH}
            // validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
            rows={4}
          />

          <FieldTextInput
            id="thingsToKnow"
            name="thingsToKnow"
            className={css.description}
            type="textarea"
            label={thingsToKnowMessage}
            placeholder={thingsToKnowPlaceholderMessage}
            validate={composeValidators(required(thingsToKnowRequiredMessage))}
            rows={3}
          />

          <FieldSegmentedCheckboxGroup 
            {...outcomesOptionsProps}
            validate={requiredFieldArrayCheckbox(requiredOneCheckboxTicked)}
          />

          <FieldTextInput
            id="duration"
            name="duration"
            className={css.description}
            type="textarea"
            label={durationMessage}
            placeholder={durationPlaceholderMessage}
            validate={composeValidators(required(durationRequiredMessage))}
          />

          <FieldTextInput
            id="price_per_hour"
            name="price_per_hour"
            className={css.description}
            type="textarea"
            label={pricePerHourMessage}
            placeholder={pricePerHourPlaceholderMessage}
            validate={composeValidators(required(pricePerHourRequiredMessage))}
          />

          <FieldTextInput
            id="minimumParticipants"
            name="minimumParticipants"
            className={css.description}
            type="text"
            label={minimumNumberOfParticipants}
            placeholder={minimumNumberOfParticipantsPlaceholder}
            validate={composeValidators(required(minimumNumberOfParticipantsRequiredMessage))}
          />

          <FieldTextInput
            id="maximumParticipants"
            name="maximumParticipants"
            className={css.description}
            type="text"
            // label={minimumNumberOfParticipants}
            placeholder={maximumNumberOfParticipantsPlaceholder}
            // validate={composeValidators(valueShouldBeNumber(pricePerHourRequiredMessage))}
          />

          <FieldCheckboxGroup 
            {...locationOptionsProps}
            validate={requiredFieldArrayCheckbox(requiredOneCheckboxTicked)}
          />

          <FieldTextInput
            id="client"
            name="client"
            className={css.description}
            type="textarea"
            label={clientLabel}
            placeholder={clientPlaceholder}
          />

          <div className={css.submitButtonWrapper}>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

SendOnboardingDataFormComponent.defaultProps = { className: null, fetchErrors: null };

SendOnboardingDataFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  // disabled: bool.isRequired,
  // ready: bool.isRequired,
  // updated: bool.isRequired,
  // updateInProgress: bool.isRequired,
  sendEmailInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(SendOnboardingDataFormComponent);
