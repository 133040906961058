import React from 'react';

import css from './ListingPage.css';

const SectionDetailsMaybe = props => {
  const { icon, text, children, moreText } = props;
  return text || children ? (
    <div className={css.sectionDetails}>
      <div className={css.detailContent}>
        {icon}
        <p className={css.detailText}>{text || children}</p>
      </div>
      {moreText && <p className={css.detailMoreText}>{moreText}</p>}
    </div>
  ) : null;
};

export default SectionDetailsMaybe;
