import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '..';
import benefit1 from './assets/cp-benefits-1.png';
import benefit2 from './assets/cp-benefits-2.png';
import benefit3 from './assets/cp-benefits-3.png';
import benefit4 from './assets/cp-benefits-4.png';
import benefit5 from './assets/cp-benefits-5.png';
import benefit6 from './assets/cp-benefits-6.png';

import css from './SectionCorporateBenefits.css';

const benefits = [
  { icon: benefit1, textId: 'SectionCorporateBenefits.benefit1' },
  { icon: benefit2, textId: 'SectionCorporateBenefits.benefit2' },
  { icon: benefit3, textId: 'SectionCorporateBenefits.benefit3' },
  { icon: benefit4, textId: 'SectionCorporateBenefits.benefit4' },
  { icon: benefit5, textId: 'SectionCorporateBenefits.benefit5' },
  { icon: benefit6, textId: 'SectionCorporateBenefits.benefit6' },
]
const SectionCorporateBenefits = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  const benefitsList = benefits.map(b => 
    <div key={b.textId} className={css.benefitItem}>
      <img src={b.icon}/>
      <h4><FormattedMessage id={b.textId} /></h4>
    </div>
  ) 
  return (
    <div className={classes}>
      <div className={css.titleContainer}>
          <FormattedMessage id="SectionCorporateBenefits.title" />
      </div>
      <div className={css.benefitsContainer}>
        {benefitsList}
      </div>
    </div>
  );
};

SectionCorporateBenefits.defaultProps = { rootClassName: null, className: null };

SectionCorporateBenefits.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCorporateBenefits;
