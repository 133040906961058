import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { sendEmail, setInitialValues } from '../../ducks/EmailSender.duck';
import { IconSpinner } from '../../components';
import config from '../../config';
import { propTypes } from '../../util/types';
import {
  Page,
  // SectionCompanies,
  // SectionExperienceCategories,
  // SectionHero,
  // SectionFeaturedExperiences,
  // SectionHowItWorks,
  // SectionSearch,
  // SectionScienceBacked,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/reviveFacebook-1200x630.jpg';
import twitterImage from '../../assets/reviveTwitter-600x314.jpg';
import css from './SendOnboardingDataPage.css';
import { withViewport } from '../../util/contextHelpers';

import { SendOnboardingDataForm } from '../../forms';

import { returnSendGrid } from '../../util/api';

import { returnFilterObject } from '../../resources/filterKeys';
// import { render } from 'enzyme';

const MOBILE_BREAKPOINT = 767;

// export class SendOnboardingDataPageComponent extends Component {
export const SendOnboardingDataPageComponent = props => {
  const { 
    intl, scrollingDisabled, viewport,
    sendEmailInProgress,
    sendEmailError,
    sendEmailSuccess,
    currentUser, onSendEmail,
    onResetForm
  } = props;
  // constructor(props) {
  //   super(props);
  //   this.state = { 
  //     enteringData: true 
  //   };
  //   this.sendMessage = this.sendMessage.bind(this);
  // }

  // componentDidMount = () =>{
  //   const { onResetForm } = this.props;
  //   onResetForm();
  // }

  const [state, setState] = useState({
    mounted: false,
    resetForm: false,
  });

  useEffect(() => {
    if (!state.mounted) {
      setState({ ...state, mounted: true });
      onResetForm();
    }
    if (sendEmailSuccess) {
      setState({ ...state, resetForm: true });
    }
  }, [sendEmailSuccess]);

  const resetOnboardingPage = () => {
    setState({ ...state, resetForm: false });
  }


  const sendMessage = (updateValues) => {

    const { 
      name,
      email,
      phone,
      company,
      businessAbout,
      categories = [],
      description,
      duration,
      facebookLink,
      instagramLink,
      linkedinLink,
      locationType = [],
      maximumParticipants,
      minimumParticipants,
      price_per_hour,
      tags = [],
      title,
      twitterLink,
      thingsToKnow,
      client,
    } = updateValues;

    const nameString = "<strong>Name: </strong>" + name + "<br/>";

    const emailStringOut = "<strong>Email: </strong>" + email + "<br/>";

    const phoneString = "<strong>Phone: </strong>" + phone + "<br/>";

    const companyString = "<strong>Company: </strong>" + company + "<br/>";

    const titleString = "<strong>Title: </strong>" + title + "<br/>";

    const categoriesLabel = returnFilterObject("categories");
    // const locationLabel = returnFilterObject("location");
    const outcomesLabel = returnFilterObject("outcomes");


    // console.log(categoriesLabel);
    // console.log(locationLabel);
    // console.log(outcomesLabel);

    var categoryArray = [];

    for (var i=0; i<categories.length; i ++) {
      const selectedCategory = categories[i];

      const categoryLabel = categoriesLabel[selectedCategory];

      // console.log(categoryLabel);
      categoryArray.push("\"" + categoryLabel + "\"");
    }

    const categoryString = categoryArray.join();

    const categoryStringOut = "<strong>Categories: </strong>" + categoryString + "<br/>";

    const businessAboutOut = "<strong>Experience Partner Bio: </strong>" + "<div style=\"white-space: pre-line;\">" + businessAbout + "</div>" + "<br/>";

    const facebookLinkOut = "<strong>Facebook: </strong>" + facebookLink + "<br/>";

    const twitterLinkOut = "<strong>Twitter: </strong>" + twitterLink + "<br/>";

    const instagramLinkOut = "<strong>Instagram: </strong>" + instagramLink + "<br/>";

    const linkedinLinkOut = "<strong>Linkedin: </strong>" + linkedinLink + "<br/>";

    const descriptionOut = "<strong>Description: </strong>" + "<div style=\"white-space: pre-line;\">" + description + "</div>" + "<br/>";


    var tagArray = [];

    for (var i=0; i<tags.length; i ++) {
      const selectedOutcome = tags[i];

      const outcomeLabel = outcomesLabel[selectedOutcome];

      // console.log(categoryLabel);
      tagArray.push("\"" + outcomeLabel + "\"");
    }

    const tagString = tagArray.join();
    const tagStringOut = "<strong>Outcomes: </strong>" + tagString + "<br/>";

    const durationOut = "<strong>Duration: </strong>" + duration + "<br/>";

    const startingPriceOut = "<strong>Starting Price: </strong>" + price_per_hour + "<br/>";

    const minimumSizeOut = "<strong>Minimum Participants: </strong>" + minimumParticipants + "<br/>";

    const maximumParticipantsOut = "<strong>Maximum Participants: </strong>" + maximumParticipants + "<br/>";

    var locationArray = []

    for (var i=0; i<locationType.length; i++) {
      const selectedLocation = (locationType[i]).trim();

      const capitalizedLocation = selectedLocation.charAt(0).toUpperCase() + selectedLocation.slice(1);

      locationArray.push("\"" + capitalizedLocation + "\"");

    }

    const locationString = locationArray.join();

    const locationStringOut = "<strong>Location: </strong>" + locationString + "<br/>";

    const thingsToKnowOut = "<strong>Things to know: </strong>" + "<div style=\"white-space: pre-line;\">" + thingsToKnow + "</div>" + "<br/>";

    const clientOut = "<strong>Clients: </strong>" + "<div style=\"white-space: pre-line;\">" + client + "</div>" + "<br/>";


    const emailString = nameString + phoneString + companyString + emailStringOut + titleString + categoryStringOut + businessAboutOut +
      facebookLinkOut + twitterLinkOut + instagramLinkOut + linkedinLinkOut + descriptionOut + tagStringOut
      + durationOut + startingPriceOut + minimumSizeOut + maximumParticipantsOut + locationStringOut + thingsToKnowOut + clientOut;

    var sendToEmail = email

    // if (currentUser && currentUser.attributes && currentUser.attributes.email) {
    //   sendToEmail = currentUser.attributes.email;
    // }

    // returnSendGrid({
    //   email: sendToEmail,
    //   subject: "New onboarding form submitted",
    //   html: emailString,
    //   addReplyTo: true,
    // });

    // this.setState({
    //   enteringData: false,
    // });

    onSendEmail({
      email,
      htmlFormatted: emailString,
      emailSubjectCustom: "New onboarding form submitted",
    });

  };

  // render() 

    var initialEmail = null;

    if (currentUser && currentUser.attributes && currentUser.attributes.email) {
      initialEmail = currentUser.attributes.email;
    }
  

    // Schema for search engines (helps them to understand what this page is about)
    // http://schema.org
    // We are using JSON-LD format
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'SendOnboardingDataPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'SendOnboardingDataPage.schemaDescription' });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const isMobileLayout = viewport.width <= MOBILE_BREAKPOINT;

    const sendEmail = intl.formatMessage({
      id: 'SendOnboardingDataForm.sendEmail',
    });

    const error = sendEmailError ? (
      <p className={css.error}>
        <FormattedMessage id="ContactPage.sendMessageFailed" />
      </p>
    ) : null;
  
    const success = sendEmailSuccess ? (
      <p className={css.success}>
        <FormattedMessage id="SendOnboardingDataPage.sendMessageSuccess" />
      </p>
    ) : null;

    var forDisplay = (
      <SendOnboardingDataForm
        className={css.form}
        initialValues={{
          email: initialEmail
        }}
        saveActionMsg={sendEmail}
        onSubmit={values => {
          const { 
            name,
            email,
            phone,
            company = "",
            title = "",
            categories = [],
            businessAbout = "",
            facebookLink = "",
            twitterLink = "",
            instagramLink = "",
            linkedinLink = "",
            description = "",
            tags = [],
            duration = "",
            price_per_hour = "",
            minimumParticipants = "",
            maximumParticipants = "",
            locationType = [],
            thingsToKnow = "",
            client = "",
          } = values;

          // console.log(values);

          const updateValues = {
            name: name.trim(),
            email: email.trim(),
            phone: phone.trim(),
            company: company.trim(),
            title: title.trim(),
            categories,
            businessAbout: businessAbout.trim(),
            facebookLink: facebookLink.trim(),
            twitterLink: twitterLink.trim(),
            instagramLink: instagramLink.trim(),
            linkedinLink: linkedinLink.trim(),
            description: description.trim(),
            tags,
            duration: duration.trim(),
            price_per_hour: price_per_hour.trim(),
            minimumParticipants: minimumParticipants.trim(),
            maximumParticipants: maximumParticipants.trim(),
            locationType, 
            thingsToKnow: thingsToKnow.trim(),
            client: client.trim(),
          };

          sendMessage(updateValues);
        }}
        // onChange={onChange}
        // disabled={disabled}
        // ready={ready}
        // updated={panelUpdated}
        // updateInProgress={updateInProgress}
        // fetchErrors={errors}
        // categories={categoryOptions}
        sendEmailInProgress={sendEmailInProgress}
        resetOnboardingPage={resetOnboardingPage}
        resetForm={state.resetForm}
        sendEmailSuccess={sendEmailSuccess}
      />
    );

    var pageSection = null;

    if (!sendEmailSuccess) {
      pageSection = (
        <div>
          {forDisplay}
          {error}
        </div>
      );
    } else {
      pageSection = (
        <div>
          {success}
        </div>
      );
    }

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.formWrapper}>
            {forDisplay}
            {error}
            {success}
            {/* {pageSection} */}
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  // }
};

const { bool, object, shape, number } = PropTypes;

SendOnboardingDataPageComponent.propTypes = {
  currentUser: propTypes.currentUser,

  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

};

const mapDispatchToProps = dispatch => {
  return {
    onSendEmail: params => dispatch(sendEmail(params)),
    onResetForm: params => dispatch(setInitialValues(params)),
  }
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  const {
    sendEmailInProgress,
    sendEmailError,
    sendEmailSuccess,
  } = state.EmailSender;

  return {
    currentUser,
    sendEmailInProgress,
    sendEmailError,
    sendEmailSuccess,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SendOnboardingDataPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport,
)(SendOnboardingDataPageComponent);

export default SendOnboardingDataPage;
