import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '..';
import groupImage from './assets/exp-partners-group.png';
import womanImage from './assets/exp-partners-woman.png';
import manImage from './assets/exp-partners-man.png';

import css from './SectionExperienceHero.css';

const SectionExperienceHero = props => {
  const { rootClassName, className, isMobileLayout } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.textContent}>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionExperienceHero.title" />
          </h1>
          <p>
          At Revive, we are improving business-to-business (B2B) experiences. We work closely with our community of Experience Partners to ensure we provide the best in-class services and uniques products to leading organization.
          </p>
          <p>
          We work closely with our Experience Partners to promote the depth and diversity of their tailored experiences.
          </p>
          <p>
          Join our community and showcase your unique expertise that will inspire people while growing your business.
          </p>
          <NamedLink
            name="SendOnboardingDataPage"
            className={css.heroButton}
          >
            <FormattedMessage id="SectionExperienceHero.addExperience" />
          </NamedLink>
        </div>
        <div className={css.imageContainer}>
          <div className={css.groupImage}>
            <img src={groupImage} className={css.imageBackground} />
          </div>
          <div className={css.imageRow}>
            <div className={css.womanImage}>
              <img src={womanImage} className={css.imageBackground} />
            </div>
            <div className={css.manImage}>
              <img src={manImage} className={css.imageBackground} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionExperienceHero.defaultProps = { rootClassName: null, className: null };

SectionExperienceHero.propTypes = {
  rootClassName: string,
  className: string,
  isMobileLayout: bool.isRequired,
};

export default SectionExperienceHero;
