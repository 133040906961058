import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { isTransactionsTransitionAlreadyReviewed } from '../../util/errors';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { FieldReviewRating, Form, PrimaryButton, FieldTextInput, FieldCurrencyInput } from '../../components';
import { convertMoneyToNumber } from '../../util/currency';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './NewOfferForm.css';
const { Money } = sdkTypes;

const toDecimal = value => Number.parseFloat(value).toFixed(2);


const NewOfferFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,
        invalid,
        offerSent,
        negotiateOfferError,
        negotiateOfferInProgress,
        currentOffer,
        initialQuote,
      } = fieldRenderProps;

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'NewOfferForm.offerRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'NewOfferForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const offer = intl.formatMessage({ id: 'NewOfferForm.offerLabel' });
      const newOfferPlaceholder = currentOffer ? toDecimal(convertMoneyToNumber(currentOffer)) : 0;
      // const newOfferRequiredMessage = intl.formatMessage({
      //   id: 'NewOfferForm.offerRequired',
      // });
      // const newOfferInvalidMessage = intl.formatMessage({
      //   id: 'NewOfferForm.invalid',
      // });

      const errorMessage = negotiateOfferError && (
        <p>
          <FormattedMessage id="NewOfferForm.offerError" />
        </p>
      );
      const errorArea = negotiateOfferError ? errorMessage : <p className={css.errorPlaceholder} />;

      const reviewSubmitMessage = intl.formatMessage({
        id: initialQuote ? 'NewOfferForm.offerSubmitInitial' : 'NewOfferForm.offerSubmit',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = negotiateOfferInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldCurrencyInput
            id={formId ? `${formId}.offer` : 'offer'}
            name="offer"
            className={css.offer}
            autoFocus
            label={offer}
            placeholder={newOfferPlaceholder}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />

          {errorArea}
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={offerSent}
          >
            {reviewSubmitMessage}
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

NewOfferFormComponent.defaultProps = { className: null, rootClassName: null, negotiateOfferError: null, currentOffer: null, };

const { bool, func, string } = PropTypes;

NewOfferFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  offerSent: bool.isRequired,
  negotiateOfferError: propTypes.error,
  negotiateOfferInProgress: bool.isRequired,
  currentOffer: propTypes.money,
  initialQuote: bool.isRequired,
};

const NewOfferForm = compose(injectIntl)(NewOfferFormComponent);
NewOfferForm.displayName = 'NewOfferForm';

export default NewOfferForm;
