/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import React from 'react';
import { arrayOf, bool, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { FieldCheckbox, ValidationError } from '..';

import css from './FieldSegmentedCheckboxGroup.css';

const FieldCheckboxRenderer = props => {

  const { className, rootClassName, label, twoColumns, id, fields, options, meta, optionsListObject, flipColumns = false } = props;

  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(css.list, css.twoColumns) : css.list;

  // console.log(fields);

  var optionCheckboxes = [];

  /*
  {options.map((option, index) => {
    const fieldId = `${id}.${option.key}`;
    return (
      <li key={fieldId} className={css.item}>
        <FieldCheckbox
          id={fieldId}
          name={fields.name}
          label={option.label}
          value={option.key}
        />
      </li>
    );
  })}*/

  for (var i=0 ; i < optionsListObject.length ; i++) {
    const optionsListObjectEntry = Object.entries(optionsListObject[i])[0][1];

    // console.log(optionsListObjectEntry.label);
    // console.log(optionsListObjectEntry.options);


    const optionListEntryOptions = optionsListObjectEntry.options;


    var optionListEntryArray = [];
    for (var j=0 ; j < optionListEntryOptions.length; j++) {
      const optionsListObjectEntryOption = optionListEntryOptions[j];

      // console.log(optionsListObjectEntryOption);
      
      const fieldId = `${id}.${optionsListObjectEntryOption.key}`;

      // console.log(fieldId);

      const optionEntry = (
        <li key={fieldId} className={css.item}>
          <FieldCheckbox
            id={fieldId}
            name={fields.name}
            label={optionsListObjectEntryOption.label}
            value={optionsListObjectEntryOption.key}
          />
        </li>
      );

      optionListEntryArray.push(optionEntry);

      // console.log(optionEntry);
    }

    const optionBoxID = Object.entries(optionsListObject[i])[0][0] + "-" + id;

    // console.log(optionBoxID);
    var sectionItemStyle = null;
    var innerListStyle = null;
    if (!flipColumns) {

      sectionItemStyle = css.sectionItem;

      if (i==0) {
        sectionItemStyle = null;
      }
    
    } else {
      innerListStyle = listClasses;
      sectionItemStyle = css.sectionItem;
      if (i==0) {
        sectionItemStyle = null;
      }
    }
    const optionCheckboxEntry = (
      <li key={optionBoxID} className={sectionItemStyle}>
        <label>{optionsListObjectEntry.label}</label>
        <ul className={innerListStyle}>
          {optionListEntryArray}
        </ul>
      </li>
    );

    optionCheckboxes.push(optionCheckboxEntry);

    // console.log(optionListEntryArray);
  }

  var mainColumns = null;

  if (!flipColumns) {
    mainColumns = listClasses;
  }
 
  return (
    <fieldset className={classes}>
      {label ? <label className={css.label}>{label}</label> : null}
      <ul className={mainColumns}>
        {optionCheckboxes}
      </ul>
      <ValidationError fieldMeta={{ ...meta }} />
    </fieldset>
  );
};

FieldCheckboxRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  twoColumns: false,
};

FieldCheckboxRenderer.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  twoColumns: bool,
};

const FieldSegmentedCheckboxGroup = props => <FieldArray component={FieldCheckboxRenderer} {...props} />;

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldSegmentedCheckboxGroup.propTypes = {
  name: string.isRequired,
};

export default FieldSegmentedCheckboxGroup;
