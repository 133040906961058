import React, { useEffect, useState } from 'react';
import config from '../../config';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { Form, IconSpinner, PrimaryButton, FieldTextInput, FieldPhoneNumberInput, } from '../../components';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';
import { required, composeValidators, emailFormatValid } from '../../util/validators';
import { sendEmail, setInitialValues } from '../../ducks/EmailSender.duck';
import PropTypes, { func, object } from 'prop-types';


import css from './ContactPage.css';
import image from './contact-girl.png';
import { propTypes } from '../../util/types';

const ContactPageComponent = (props) => {
  const { intl, onSendEmail, sendEmailInProgress, sendEmailError, sendEmailSuccess, onResetForm, currentLocation } = props;
  const [state, setState] = useState({
    mounted: false,
    resetForm: false,
  });

  const handleFormSubmit = values => {
    const { name, email, subject, phone, message } = values;
    const requiredMessage = intl.formatMessage({
      id: 'ContactPage.requiredField',
    });
    const invalidEmailMessage = intl.formatMessage({
      id: 'ContactPage.invalidEmail',
    });
    const errors = {
      name: required(requiredMessage)(name),
      email: required(invalidEmailMessage)(email),
      phone: required(requiredMessage)(phone),
      subject: required(requiredMessage)(subject),
      message: required(requiredMessage)(message),
    }

    const withErrors = Object.keys(errors).filter(key => errors[key] !== undefined);
    if (withErrors.length > 0) {
      return errors;
    }
    onSendEmail(values);
  }

  useEffect(() => {
    if (!state.mounted) {
      setState({ ...state, mounted: true });
      onResetForm();
    }
    if (sendEmailSuccess) {
      setState({ ...state, resetForm: true });
    }
  }, [sendEmailSuccess]);

  const loadingSpinnerMaybe = sendEmailInProgress ? (
    <IconSpinner className={css.spinner} />
  ) : null;

  const error = sendEmailError ? (
    <p className={css.error}>
      <FormattedMessage id="ContactPage.sendMessageFailed" />
    </p>
  ) : null;

  const success = sendEmailSuccess ? (
    <p className={css.success}>
      <FormattedMessage id="ContactPage.sendMessageSuccess" />
    </p>
  ) : null;
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'ContactPage.schemaTitle' }, { siteTitle });

  // prettier-ignore
  return (
    <StaticPage
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ContactPage',
        description: 'Send us a message',
        name: 'Contact Us',
      }}
    >
      <LayoutSingleColumn rootClassName={css.root}>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>

          <div className={css.contentWrapper}>
            <div className={css.contentSide}>
              <img className={css.coverImage} src={image} alt="My first ice cream." />
            </div>

            <div className={css.contentMain}>
              <h1 className={css.pageTitle}><FormattedMessage id="ContactPage.title" /></h1>
              <h3 className={css.pageSubtitle}>
              We'd like to hear from you.  Drop us a note, and we'll get back to you in a timely manner!
              </h3>
              
              <FinalForm
                onSubmit={handleFormSubmit}
                render={fieldRenderProps => {
                  const {
                    handleSubmit,
                    form,
                  } = fieldRenderProps;
                  
                  // const requiredMessage = intl.formatMessage({
                  //   id: 'ContactPage.requiredField',
                  // });
                  // const invalidEmailMessage = intl.formatMessage({
                  //   id: 'ContactPage.invalidEmail',
                  // });
                  if (sendEmailSuccess && state.resetForm) {
                    setTimeout(() => {  
                      setState({ ...state, resetForm: false });
                      form.restart(); 
                    }, 500);
                  }

                  const namePlaceholder = intl.formatMessage({ id: 'SectionContact.namePlaceholder' });
                  const nameLabel = intl.formatMessage({ id: 'SectionContact.name' });
                  const emailPlaceholder = intl.formatMessage({ id: 'SectionContact.emailPlaceholder' });
                  const emailLabel = intl.formatMessage({ id: 'SectionContact.email' });
                  const phonePlaceholder = intl.formatMessage({ id: 'SectionContact.phonePlaceholder' });
                  const phoneLabel = intl.formatMessage({ id: 'SectionContact.phone' });
                  const subjectPlaceholder = intl.formatMessage({ id: 'SectionContact.subjectPlaceholder' });
                  const subjectLabel = intl.formatMessage({ id: 'SectionContact.subject' });
                  const messagePlaceholder = intl.formatMessage({ id: 'SectionContact.messagePlaceholder' });
                  const messageLabel = intl.formatMessage({ id: 'SectionContact.message' });

                  return (
                    <Form onSubmit={handleSubmit}>
                      <div className={css.formRow}>
                        <FieldTextInput
                          id="form-name"
                          name="name"
                          className={css.textField}
                          type="text"
                          label={nameLabel}
                          placeholder={namePlaceholder}
                          // validate={required(requiredMessage)}
                          // useMobileMargins
                        />
                        <FieldTextInput
                          id="form-email"
                          name="email"
                          className={css.textField}
                          type="email"
                          label={emailLabel}
                          placeholder={emailPlaceholder}
                          // validate={emailFormatValid(invalidEmailMessage)}
                          // useMobileMargins
                        />
                      </div>
                      <div className={css.formRow}>
                        <FieldPhoneNumberInput
                          id="form-phone"
                          name="phone"
                          className={css.textField}
                          type="text"
                          label={phoneLabel}
                          placeholder={phonePlaceholder}
                          // validate={required(requiredMessage)}
                          // useMobileMargins
                        />
                        <FieldTextInput
                          id="form-subject"
                          name="subject"
                          className={css.textField}
                          type="text"
                          label={subjectLabel}
                          placeholder={subjectPlaceholder}
                          // validate={required(requiredMessage)}
                          // useMobileMargins
                        />
                      </div>
                      <div className={css.formRow}>
                        <FieldTextInput
                        id="message"
                        name="message"
                        className={css.textArea}
                        type="textarea"
                        label={messageLabel}
                        placeholder={messagePlaceholder}
                        // validate={required(requiredMessage)}
                        rows={5}
                        />
                      </div>
                      <div className={css.submitButtonWrapper}>
                        <PrimaryButton type="submit" disabled={sendEmailInProgress}>
                          {loadingSpinnerMaybe}
                          {!sendEmailInProgress && <FormattedMessage id="ContactPage.sendMessage" />}
                        </PrimaryButton>
                      </div>
                      {error}
                      {success}
                    </Form>
                  );
                }}
              />
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};
const { bool } = PropTypes;

ContactPageComponent.defaultProps = {
  currentLocation: null,
};

ContactPageComponent.propTypes = {
  sendEmailInProgress: bool.isRequired,
  sendEmailError: propTypes.error,
  sendEmailSuccess: bool.isRequired,
  onSendEmail: func.isRequired,
  onResetForm: func.isRequired,
  currentLocation: object,

  // from injectIntl
  intl: intlShape.isRequired,
};


const mapStateToProps = state => {
  const {
    sendEmailInProgress,
    sendEmailError,
    sendEmailSuccess,
  } = state.EmailSender;
  const {
    currentLocation
  } = state.Routing;

  return {
    sendEmailInProgress,
    sendEmailError,
    sendEmailSuccess,
    currentLocation,
  }
};
const mapDispatchToProps = dispatch => {
  return {
    onSendEmail: params => dispatch(sendEmail(params)),
    onResetForm: params => dispatch(setInitialValues(params)),
  }
};

const ContactPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ContactPageComponent);

export default ContactPage;
