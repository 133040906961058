import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>
        Let’s Revive Us, Inc. (herein known as “Company”), is a corporation headquartered at 1626 Montana Ave. Suite 606 Santa Monica, CA 90403. 
      </p>

      <h2>Copyright</h2>
      <p>
        All content provided on this Website is the copyright of the Company or its content suppliers. It is prohibited to copy, reproduce, modify, display, republish, upload, post, transmit, distribute, alter, prepare any derivative works of, or otherwise use any material from this Website without the prior express written consent of Let’s Revive, Inc.
      </p>

      <h2>Trademarks</h2>
      <p>
      The Company’s trademarks may not be used in connection with any product or service that is not the property of the Company, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits the Company or its affiliates. Other registered trademarks and trademarks used on this site are the property of their respective owners and are used herein solely for descriptive purposes. Mention on this Website of trademarks held by other parties should not be construed as a challenge to said trademarks’ status or owner.
      </p>

      <h2>Disclaimer</h2>
      <p>
      This Website is provided by the Company on as “as-is” basis. The Company makes no express or implied representations or warranties of any kind, as to the operation of the Website, or the content, information, materials, or products included on this Website. To the fullest extent permissible by applicable law, the Company disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose. The Company will not be liable for any damages of any kind resulting from the use of this Website, including but not limited to direct, indirect, incidental punitive and consequential damages. The Company does not warrant that the Website will operate error-free or that this Website and its server are free or computer viruses and other harmful goods. If your use of the Website or the material results in the need for servicing or replacing equipment or data, the Company is not responsible for those costs.
      </p>

      <h2>No Medical Advice</h2>
      <p>
      The Site and the Content are for informational purposes only. No medical advice is rendered through the Site by Let’s Revive Us and the Content is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health care provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you read on the Site. Let’s Revive Us does not recommend or endorse any specific procedures, products, tests, physicians, opinions, or other information that may be mentioned on the Site. Reliance on any information provided on the Site, whether by Let’s Revive Us or any other party is solely at your own risk. If you think you may have a medical emergency, call your physician or 911 immediately.
      </p>

      <h2>Applicable Law</h2>
      <p>
      This Website is created and controlled by the Company in the State of California. As such, the laws of the State of California will govern these disclaimers, terms, and conditions, without giving effect to any principles of conflicts of laws. By use of this Website you irrevocably consent for any and all disputes with the Company to the venue of state or federal courts located in the State of California. We reserve the right to make changes to our site and these disclaimers, terms, and conditions at any time with or without notice to its users. Unless otherwise expressly provided in a legal notice, the aggregate liability for the Company to you for all claims arising from the use of the Website is limited to $100.
      </p>

      <h2>Web Traffic Tracking</h2>
      <p>
      Google Analytics is used to analyze traffic to this website. Google Analytics does not create individual profiles for visitors, nor Google Analytics does have a database of individual profiles for each visitor. Google Analytics only collects aggregate data. For more information about the privacy policy for Google Analytics, please <a href="http://www.google.com/intl/en/policies/" target="_blank">click here</a>.
      </p>

      <h2>Address of Record</h2>
      <p>
      Let’s Revive Us, Inc., 1626 Montana Ave. Suite 606 Santa Monica, CA 90403.
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
